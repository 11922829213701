@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include scrollbar;
}

.maxHeight {
  max-height: 11.75rem;
  overflow-y: auto;
  @include lg {
    max-height: 8.25rem;
  }
}

.search {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkboxWithAmount {
  margin-left: 0.25rem;
}

.disabled {
  color: $darkGray34K;
  border-color: $darkGray34K;
  opacity: unset;
}
