@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/elevations.scss';

.header {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  border-radius: 0;
  @include dropShadow2;
  justify-content: center;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  z-index: 999;
}

.headerWrapper {
  padding: 1rem 0.5rem;
}
