@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'assets/theme/mixins.scss';

$modalWidth: 52.3125rem;

.modal {
  max-width: calc(100% - 1.5rem);
  width: $modalWidth;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  height: fit-content;

  @include below-sm {
    max-width: 100%;
    height: 100%;
  }
}

.wrapper {
  background-color: $lightGray5K;
  padding: 2.5rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  $bottomBtnSectionHeight: 3.5rem;

  @include below-sm {
    padding: calc(2.5rem + var(--obeta-safe-area-top-root)) 1.5rem
      calc($bottomBtnSectionHeight + 1.5rem) 1.5rem;
  }

  .closeButton {
    padding: 0;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      color: rgba(63, 63, 62, 1);
    }

    @include below-lg {
      width: 2rem;
      height: 2rem;
    }
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .configurationSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .printOption {
      .settingsLink {
        text-underline-position: under;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .printInfoText {
    margin-left: 1.725rem;
    text-align: left;

    @include below-lg {
      margin-left: 2.5rem;
    }
  }

  .bottomSection {
    @include below-sm {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      z-index: 99;
      background: $lightGray5K;
    }

    .bottomSectionButtonsWrapper {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      gap: 1.5rem;

      @include below-sm {
        padding: 0.75rem 1.5rem;
      }
    }
  }
}
