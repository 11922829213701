@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  @include sm {
    align-items: center;
    flex-direction: row-reverse;
    gap: 1.5rem;
  }
}

.containerMobile {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  gap: 1rem;
}

.actionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popover {
  padding: 1rem 1rem calc(1rem + var(--obeta-safe-area-bottom-root)) 1rem;
  @include rowGap(1.5rem);
}
