@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.overlayWrapper {
  background-color: $white;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.overlayWrapperMobile {
  @include dropShadow2;
}

.backButton {
  padding: 0 0 0.625rem 0;
  background: $lightGray5K;
}

.mapContainer {
  height: 26.875rem;

  @include below-sm {
    height: 20.625rem;
  }
}

.modalContent {
  max-width: $shopMaxWidth;
  @include below-sm {
    width: calc(100% - #{2 * $marginSm});
  }

  @include above-sm {
    width: calc(100% - #{2 * $shopMdThreshold});
  }
}

.screenContentWrapper {
  height: auto;
  background-color: $white;
}

.stackScreen {
  position: static;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: unset;
  padding: 0;
  background: $white;
}

.stackScreenDetail {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  margin: 0;
  z-index: 1000;
}
