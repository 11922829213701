@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.actionButton {
  border-color: $lightGray;
  gap: 0.5rem;
  &:hover {
    border-color: $darkGray;
  }
  &:active {
    border-color: $lightGray;
    color: $darkGray;
  }
  &.Mui-disabled {
    border-color: $lightGray;
    color: $darkGray;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.whiteHover {
  &:hover {
    color: #ffffff;
  }
}

.menuItem {
  min-width: 10.813rem;
}

.menuPaper {
  max-height: 11rem;
}

.editOrNavigationButtonWrapper {
  display: flex;
  gap: 1rem;
  :global .MuiButton-root {
    min-width: 2rem;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    @include lg {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
  :global .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0;
  }
}

.abortIdsButton {
  margin-right: 0.75rem;
  @include sm {
    margin-right: 1rem;
  }
}

.idsButtonBox {
  :global .MuiButton-root:nth-child(2) {
    min-width: 2rem;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    @include sm {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }

  .continueOrSubmitIdsButton {
    :global .MuiButton-startIcon {
      margin: 0;
      @include sm {
        margin-right: 0.5rem;
      }
    }
  }
}

.actionButtons {
  display: flex;
  gap: 1rem;
}
