@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.accessories {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.list {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
}

.cardWrapper {
  position: relative;
  width: 16rem;
  padding: 5px;
}
