@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.card {
  padding: 0;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  &:hover {
    @include dropShadow2;
  }
}

.horizontalCard {
  width: 100%;
}

.content {
  max-width: 100%;
  min-width: 0;
  padding: 1rem;
  &:last-child {
    padding: 1rem;
  }
}

.stretch {
  width: 100%;
}

.productImageWrap {
  width: 100%;
  height: 100%;
}

.productImage {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  object-position: center;
}

.pdfPoductDescription {
  font-size: 0.75rem; //12px
  line-height: 1.25rem; //20px
}

.articleNumWithIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.stocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row;
}

.subActions {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.aligned {
    position: absolute;
    right: 0;
    padding-right: 1rem;
  }
}

.labelsContainer {
  display: flex;
}

.replacementLinkVerticalContainer {
  display: flex;
  grid-column: 1 / -1;
  justify-content: flex-end;
}
