@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.strength-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  padding: 5px; //shadow

  &.compact {
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding-left: 5px;
    > * {
      flex: 1 0 0;
      overflow: hidden;
    }
  }
}

.swiperContainer {
  margin: -5px calc(0px - $shopXsThreshold - 5px);

  :global {
    .swiper {
      /* padding prevents a shadow of being cut off */
      padding: 5px;
    }

    .swiper-wrapper {
      padding: 0 $shopXsThreshold;
    }

    .swiper-slide {
      height: auto;
      width: auto;

      /* A workaround for a problem when the last slide is half-cut */
      &:last-child {
        display: flex;
      }

      &:last-child::after {
        content: '';
        width: calc($shopXsThreshold * 2);
      }

      > * {
        height: 100%;
      }
    }
  }
}
