@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.allCheckbox {
  padding-left: 0.55rem;
  padding-top: 1rem;
  @include sm {
    padding: 0 0.75rem;
  }
}

.filterCard {
  height: 2rem;
  @include below-sm {
    margin-top: 0.5rem;
  }
  @include sm {
    padding-left: 0.75rem;
    margin-left: 0.5rem;
  }
}

.disabled {
  border: 1px solid $lightGray;
  color: $darkGray34K !important;
  :global .MuiTypography-root {
    color: $darkGray34K;
  }
}

.red {
  border: 1px solid $red100pct;

  &:hover {
    box-shadow: 0 0 15px 0 #00000026;
    border: 1px solid $red100pct;
    color: $darkGray;
    background-color: $white;
  }
}

.gray {
  border: 1px solid $lightGray;

  &:hover {
    box-shadow: 0 0 15px 0 #00000026;
    border: 1px solid $darkGray;
    color: $darkGray;
    background-color: $white;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.editingHeaderMobile {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  :global .MuiFormControlLabel-root {
    margin-left: 0.188rem;
    color: $darkGray;
  }

  > .allLabel {
    @include below-lg {
      margin-top: 1rem;
      margin-left: 0.25rem;
    }
  }
}

.editingHeader {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > .allLabel {
    margin-left: 0.344rem;
    color: $darkGray;
  }
  @include sm {
    height: 2rem;
  }
}

.genericHeader {
  justify-content: space-between;
  @include below-sm {
    margin-bottom: 1rem;
  }
}

.outterBox {
  :global .MuiButton-root {
    min-width: 2rem;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    @include lg {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
  :global .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0;
  }
}

.checkboxMobile {
  span {
    width: 100px;
  }
}

.icon {
  height: 2rem;
  width: 2rem;
  @include sm {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.formControlLabel {
  height: 2rem;
}

.wrapper {
  flex-grow: 2;
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5rem;
  @include sm {
    margin-right: 1.5rem;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  @include below-sm {
    gap: 0.5rem;
  }
}
