@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

$mobileSidebarPadding: 1.5rem;
$mobileActionsHeight: 64px;
$itemHeight: 1.5rem;

.mobileRoot {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: $white;
  gap: 1rem;
  overflow: auto;
  padding: $mobileSidebarPadding;
  padding-top: calc($mobileSidebarPadding + var(--obeta-safe-area-top));
  padding-bottom: calc(
    $mobileActionsHeight + $mobileSidebarPadding + var(--obeta-safe-area-bottom-root)
  );
}

.closeButton {
  padding: 0;

  .icon {
    width: 2rem;
    height: 2rem;
    color: rgba(63, 63, 62, 1);
  }
}

.sidebarRoot {
  color: $darkGray;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @include md {
    width: 16rem;
  }
}

.item {
  height: $itemHeight;
  padding: 6px;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.radioFilterSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
