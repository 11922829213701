@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.cardWrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
  background: $white;
  border-radius: 0.25rem;
  box-sizing: border-box;
  @include dropShadow1;
  height: fit-content;

  .imageWrapper {
    width: 100%;
    height: 12rem;

    @include below-sm {
      height: 112px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    .title {
      margin-bottom: 0.5rem;
    }
  }
}
