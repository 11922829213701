@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.indicator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 1s;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.25rem;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.indicatorOfferInquiry {
  border-radius: 0.25rem;
}

.circle1,
.circle2,
.circle3 {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  animation-fill-mode: both;
  background-color: $red100pct;
}

/**
    TODO: in case there will be different values (duration, delay) use
    scss variables to calculates idents

    I used idents to implement anim delay
    (unfortunately you can't use anim-delay, it works only to delay start of animation
    it doesn't allow to wait after animation ended)

    Logic behind values:

    animDelay = 0.125
    timeToGrow = 0.5
    tomeToShrink = timeToGrow
    circle1 = timeToGrow (grow) -> animDelay*2 (wait c2 and c3 to grow) -> timeToShrink (shrink)
    circle2 = animeDelay (wait) -> timeToGrow (grow) -> animDelay (wait c3 to grow) -> animDelay(wait c1 to shrink) -> timeToShrink (shrink)
    circle3 = animeDelay*2 (wait) -> timeToGrow (grow) -> animDelay*2 (wait c1 and c2 to shrink)  -> timeToShrink (shrink)
*/

$scaleStart: 0;
$scaleEnd: 0;
$duration: 1.5;
$direction: 'normal';
$timingFunction: 'ease-in-out';

.circle1 {
  @include indicatorKeyframes(
    'circle1',
    $scaleStart,
    $scaleEnd,
    $duration,
    $direction,
    $timingFunction
  ) {
    33.33% {
      transform: scale(1);
    }
    50% {
      transform: scale(1);
    }
    83.33% {
      transform: scale(0);
    }
  }
}

.circle2 {
  @include indicatorKeyframes(
    'circle2',
    $scaleStart,
    $scaleEnd,
    $duration,
    $direction,
    $timingFunction
  ) {
    8.33% {
      transform: scale(0);
    }
    41.66% {
      transform: scale(1);
    }
    50% {
      transform: scale(1);
    }
    58.33% {
      transform: scale(1);
    }
    91.66% {
      transform: scale(0);
    }
  }
}

.circle3 {
  @include indicatorKeyframes(
    'circle3',
    $scaleStart,
    $scaleEnd,
    $duration,
    $direction,
    $timingFunction
  ) {
    16.66% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    66.66% {
      transform: scale(1);
    }
  }
}
