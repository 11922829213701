@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.chat {
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  height: 100%;

  &--small {
    height: 1.1875rem;
    width: 1.25rem;
  }

  &--medium {
    width: 1.4375rem;
    height: 1.25rem;
  }

  &--active {
    --background: #3f3f3e;
  }
}

.count {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  font-family: 'Roboto', sans-serif;
  &--small {
    font-size: 0.5625rem;
    line-height: 0.75rem;
  }

  &--medium {
    font-size: 0.75rem;
    line-height: 0.675rem;
  }
}
