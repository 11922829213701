@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.properties {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1.5rem;
  height: fit-content;
  @include md {
    order: unset;
    grid-area: ProductProperties;
  }
  color: $darkGray;
}

.section {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
}
.underline {
  border-bottom: 0.0625rem solid;
  cursor: pointer;
}
