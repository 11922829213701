@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &.vertical {
    :global .swiper-slide {
      height: auto;
    }
  }
  &.horizontal {
    :global .swiper-slide {
      width: auto;
    }
  }
}
