@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.search-wrapper {
  padding: 0.5rem;
  background-color: #fff;
}

.search {
  width: 100%;
  position: relative;
}

.icon {
  height: 100%;
  display: flex;
  padding: 0 1rem;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: center;
}

.input-root {
  width: 100%;
  height: 100%;
  color: inherit;
}

.input {
  width: 100%;
  padding: 0.5rem 0 0.5rem 3.5rem;
  height: 1.5rem;
  font-size: 1rem;
  &::placeholder {
    opacity: 0.55;
  }
}
