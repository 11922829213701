@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.billing {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;
  @include lg {
    gap: 1.5rem;
  }
}

.payments {
  display: flex;
  flex-direction: column;
}

.requiredText {
  display: flex;
  justify-content: flex-end;
}
