@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.experimentalClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: 'vertical';
  overflow: hidden;
  text-align: left;
  white-space: normal;
}
