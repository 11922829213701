@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.root {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 0.5rem;
  overflow: initial;
}

.filters {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 0.25rem 0.5rem;
}

.chip {
  min-width: 0;
}

.highlighted {
  border: unset;
  background-color: $green;
  * {
    color: $white;
    fill: $white;
  }
}
