@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.mainWrapper {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
}

.appMainContent {
  width: 100%;
  height: 100%;
}

$navigationHeight: 4.125rem;

.selectionBarContainer {
  bottom: calc($navigationHeight + var(--obeta-safe-area-bottom-root));
}
