@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.popover {
  width: 100%;
  padding: 0;
  max-width: unset;
  max-height: unset;
  border-radius: 0;
  top: unset !important; // reset default styles set via style attr
  bottom: 0 !important; // reset default styles set via style attr
  left: 0 !important; // reset default styles set via style attr
  position: fixed;
  box-shadow: none;
  background-color: transparent;
  @include dropShadow2;
}

.popoverPaper {
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.25rem;
  width: 100%;
  @include dropShadow2;
}
