@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

$borderWidth: 1px;

.animatedButton {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  padding: $borderWidth;
  border-radius: 5px;
  cursor: pointer;
}

.svg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  .outline {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    fill: transparent;
    stroke-width: 2px;
    stroke-dasharray: 100;
    stroke: transparent;
  }

  .loadingAnimation {
    stroke: $red100pct;
    stroke-dasharray: 50;
    animation: borderAnimation 2.5s ease-in-out infinite;
  }
}

@keyframes borderAnimation {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.notificationButton {
  padding: $borderWidth;
  button {
    background-color: $green !important;
  }
  &.error button {
    background-color: $red100pct !important;
  }
}
