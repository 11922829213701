@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.circle {
  margin-right: 0.25rem;
}

.orange {
  color: $orange;
}
.red {
  color: $red100pct;
}
.mr_1 {
  margin-right: 1rem;
}

.box {
  display: flex;
  align-items: center;
  min-width: 0;
}

.typeOfStock {
  padding-right: 0.25rem;
}

.unit {
  padding-left: 0.125rem;
}

.amount {
  @include ellipsis;
}
.controlledTooltipTrigger {
  text-decoration: underline;
  text-underline-offset: 2px;
}
