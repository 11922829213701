@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.dialog {
  :global .MuiPaper-root {
    width: 28.875rem;
    padding: 2.5rem 2.5rem 2.188rem 2.5rem;
  }
  :global .MuiDialogActions-root {
    padding-top: 1rem;
  }
}

.divider {
  margin-top: 2rem;
}

.telephoneField {
  width: 100%;
  margin-top: 0.25rem;
}

.requiredField {
  margin-top: 0.25rem;
  text-align: right;
}

.marginTop {
  margin-top: 1rem;
}
