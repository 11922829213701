@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.root {
  height: 6.25rem;
  border-radius: 0.25rem;
  background-color: #ffffff;
  box-sizing: border-box;
}

.row {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
}

.image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  max-width: 8rem;
  min-width: 0.063rem;
  margin: 1rem;
}

.metawrap {
  overflow: hidden;
  flex: 1;
  padding: 1rem;
}

.skeleton {
  width: 100%;
  height: 100%;
}

.titleSkeleton {
  width: 100%;
  height: 1.5rem;
}

.contentSkeleton {
  width: 50%;
  margin-top: 1rem;
  height: 1.5rem;
}
