@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.MuiFormHelperText-root.Mui-error {
  margin-left: 0; // prevents indentation of validation error shown in helper text
}

.divider {
  padding-bottom: 1rem;
}

.cancelButton {
  margin-right: 1.5rem;
}
.editingButtonsGrid {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.helperTextIcon {
  margin-right: 0.25rem;
}

.text_input {
  margin-top: 0.25rem;
  width: 100%;
}
