@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.container {
  display: flex;
  gap: 1.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
}

.filters {
  display: none;
  @include md {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 16rem;
  }
  &Content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
