@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations';

.topSectionBackground {
  @include grayFullWidthBg;
  z-index: 0;
}

.sectionWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @include dropShadow2;
  background: #fff;
  border-radius: 0.25rem;
  padding: 3.375rem 3.75rem;
  margin: 1.25rem 0;
  &.mobileWrapper {
    padding: 1.375rem 1.75rem;
  }
}

.info {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.loginButton {
  max-width: 37.5rem;
  margin-top: 2rem;
  flex-grow: 1;

  :global .MuiTypography-root {
    font-size: 1rem;
  }
}
