@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.formContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  width: 45.625rem;
  max-width: 45.625rem;
  margin: 0 0 0 0;
}

.formContainerTabletWide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  width: 44.5rem;
  max-width: 44.5rem;
  margin: 0 0 0 0;
}

.formContainerMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.TextField {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.helperTextIcon {
  margin-right: 0.5rem;
}

.helperTextContainer {
  margin: 0.25rem 0 0 0;
}
