@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.title {
  margin-bottom: 1rem;
}

.openPositionsTitle {
  font-size: 1rem;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  @include lg {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.openPositionsSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.subTitleLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.accountId {
  font-size: 1rem;
}
