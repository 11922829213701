@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.wrapper {
  padding-left: 1.725rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include below-lg {
    padding-left: 2.5rem;
  }

  @include below-md {
    padding-left: 0;
  }
}

.isUsedOnSettingsPageWrapper {
  padding-left: 0;
}
