@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.hideModal {
  visibility: hidden;
}

.showModal {
  visibility: visible;
}

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: $white;
  gap: 1rem;
  overflow: auto;
  padding: var(--ion-safe-area-top-root, 0px);

  // Only for browser usage in App
  // we can't rotate and lock screen in browser the same as app
  &.forceRotateAppWeb {
    @include below-md {
      @media (orientation: portrait) {
        transform: rotate(90deg) !important;
        transform-origin: top left;
        width: 100vh;
        height: 100vw;
        position: absolute !important;
        top: 0 !important;
        left: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
    }
  }

  &.isLoggedIn {
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../../../../../../libs/assets/img/Customer_Account/customerCardBackground.jpeg')
        no-repeat;
      background-size: cover;
      z-index: -1;
      pointer-events: none;
    }
  }

  .close {
    top: calc(0.5rem + var(--obeta-safe-area-top-root));
    right: 2.5rem;

    &.contentLoggedIn {
      top: 1rem;
      right: calc(
        2.5rem + var(--obeta-safe-area-top-root, 0rem) + var(--obeta-safe-area-right-root, 0rem)
      );
    }
    z-index: 2;
    padding: 4px;
  }

  .closeLoggedIn {
    color: #fff;
  }

  .content {
    padding-top: calc(2rem + var(--obeta-safe-area-top-root, 0rem));

    &.contentLoggedIn {
      padding-top: 3.5rem;
    }

    width: 100%;
    height: 100%;
  }
}
