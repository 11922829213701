@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.root {
  position: absolute;
  width: 100%;
  height: 60px;
  z-index: 2;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: transform 1s ease;

  .icon {
    width: 30px;
    height: 30px;
  }

  &.pulling {
    transition: none;
  }

  &.pulled {
    .icon {
      animation: rotate 1s linear infinite;
    }
  }

  &.refreshing {
    .icon {
      animation: rotate 1s linear infinite;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
