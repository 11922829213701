@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.datesContent {
  display: flex;
  gap: 1rem;
  &Item {
    &Id {
      text-decoration: underline;
    }
  }
  &Section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
