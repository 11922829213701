@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.marBottomOneRem {
  margin-bottom: 1rem;
}

.marginRightHalfRem {
  margin-right: 0.5rem;
}

.headerButtons {
  display: flex;
  gap: 1.5rem;
}

.wrapperButtonsAndDataHeader {
  display: flex;
  justify-content: space-between;
}

.tabHeader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.wrapperGoBackButton {
  display: flex;
}
