@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.mapInfoOutline {
  padding: 1.5rem;
  background: $white;
  &:empty {
    display: none;
  }
}

.mapInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: $lightGray5K;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.mapInfoMain {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto auto;
  gap: 2.5rem;

  @include below-sm {
    gap: 1rem;
    grid-template-columns: auto;
  }
}

.mapInfoTop {
  display: flex;
  justify-content: space-between;
}

.detailedInfoIcons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.detailedInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detailedContentText {
  word-break: break-all;
}

.infoIcons {
  display: flex;
  gap: 0.5rem;

  .infoIcon {
    cursor: pointer;
  }
}

.infoIconsBottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;

  @include below-sm {
    gap: 0.5rem;
  }
}

.iconWithText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
