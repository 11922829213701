@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.wrapper {
  padding: 2.5rem 0.5rem;

  .metalQuotationSection {
    .metalQuotationTitle {
      margin-bottom: 1rem;
    }

    .quotationItems {
      display: flex;
      gap: 0.875rem;

      .quotationItem {
        border-radius: 3.75rem;
        background-color: $lightGray8K;
        padding: 0.25rem 0.75rem;
      }
    }

    margin-bottom: 1.5rem;
  }

  .legalNote {
    text-align: center;
    padding: 0.375rem 0.5rem;
    display: flex;
    justify-content: space-between;

    @include above-sm {
      justify-content: flex-start;
      gap: 4.1875rem;
    }

    a {
      background-color: transparent;
    }
  }
}
