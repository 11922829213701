@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';

.wrapperContainer {
  background-color: $white;
  padding: 2.5rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  @include dropShadow2;
  border: none;
}

.headerBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.marTopOneRem {
  margin-top: 1rem;
}

.marBottomOneRem {
  margin-bottom: 1rem;
}

.marTopHalfRem {
  margin-top: 0.5rem;
}

.marBottomHalfRem {
  margin-bottom: 0.5rem;
}

.textField {
  width: 100%;
  :global .MuiOutlinedInput-root {
    height: 3.25rem;
  }
}

.divCharCount {
  text-align: right;
}

.boxButtons {
  display: flex;
  justify-content: end;
  > :first-child {
    margin-right: 1.5rem;
  }
}

.obetaRed {
  color: $red100pct;
}
