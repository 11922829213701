@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.icon {
  justify-self: flex-end;
  align-self: center;
  color: $darkGray;
  margin-left: auto;
}

.textWithAmount {
  display: flex;
}

.text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 0.25rem;
}

.etim {
  box-sizing: border-box;
  background-color: $white;
  border-color: $lightGray;
  min-height: $desktopEtimItemHeight;
  max-height: $desktopEtimItemHeight;
  overflow: hidden;
  padding: 0.375rem 0.5rem;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }

  .textEtim {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }
}

.dropdown {
  button div {
    display: flex;
    max-width: 100%;
  }
}
