@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

$maxRowsVisible: 3;
$itemsGap: 0.5rem;

.articleClasses {
  display: grid;
  grid-gap: 0.5rem;
  max-height: $maxRowsVisible * $desktopEtimItemHeight + $itemsGap * $maxRowsVisible - $itemsGap;
  overflow: hidden;
  width: 100%;
}

.toggleMore {
  margin: 0 auto;
  margin-top: 1rem;
}

.showMore {
  max-height: unset;
}

.articleClass {
  @include actionLightGrayTheme;
  padding: 0.375rem 1.3125rem;
  border-radius: 0.25rem;
  height: fit-content;
}

:export {
  maxRowsVisible: $maxRowsVisible;
  itemsGap: $itemsGap;
}

.container {
  margin-bottom: 1rem;
}
