@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
