@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';

$height: 3.25rem;
$tabletHeight: 3.25rem;
$mobileHeight: 3.75rem;
$headerFullHeight: 8.5rem;

$minHeightCategoryList: 26rem; //416px;

.bottomSectionWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.bottomHeaderSection {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  height: $mobileHeight;
  background-color: $white;
  border-top: 1px solid $lightGray8K;
  border-bottom: 2px solid $red100pct;

  @include sm {
    height: $tabletHeight;
    align-items: center;
    background-color: $red100pct;
    padding: 0;
    border: 0;
  }

  @include md {
    height: $height;
    background-color: $red100pct;
    padding: 0;
    border: 0;
  }

  .rightSideBottomSection {
    display: flex;
  }
}

.bottomHeaderContent {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 78.125rem;

  @include sm {
    margin: 0 1rem 0 1rem;
  }

  @include md {
    margin: 1rem 2.25rem 0;
  }
  @include lg {
    margin: 1rem 1.25rem 0 1.25rem;
  }
}

.bottomHeaderSectionMobile {
  padding: 0.5rem 1rem 0.5rem;
}

.hiddenSection {
  width: 100%;
  height: 0.25rem;
  background-color: $red100pct;
}

.moreIcon {
  margin-left: 1rem;
}

.searchbar {
  margin-left: 1rem;
  height: 2rem;
  width: 100%;
}

.menuItemWrapper {
  display: flex;
}

.menuItemWithMargin {
  margin-right: 2rem;
}

.menuItemWithMarginLast {
  margin-right: 0.5rem;
}

:export {
  sectionHeight: $headerFullHeight;
  minHeightCategoryList: $minHeightCategoryList;
  singleObetaLevel0ListItemheight: 3.5rem; //56px;
  listSubheaderheight: 3rem; //48px;
  heightDivHoldingBackButton: 3.5rem; //56px;
}

.divListWrapper {
  background-color: $white;
  position: fixed;
  top: 10.75rem; //172px; //(120 + 52) which is overridden whenever needed

  //This is for firefox only:
  scrollbar-color: $darkGray55K white;
}

.divListWrapperScrollable {
  overflow: auto;
  // max-height: has been set dynamically in code

  $scrollBarWidth: 0.5rem;
  &::-webkit-scrollbar {
    width: $scrollBarWidth;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 31px;
    background-color: $darkGray55K;
  }
}

.categoryList {
  //Design feedback set a fixed width of 400px
  width: 25rem; //400px;
  min-height: $minHeightCategoryList;
}

.noHover :global .MuiListItem-root {
  .MuiButtonBase-root {
    &:hover {
      background-color: transparent;
    }
  }
}

.categoryList :global .MuiListSubheader-root {
  position: unset;
}

.listItemButton {
  padding-top: 0;
  padding-bottom: 0;
  color: $darkGray;
}

.listItemText {
  margin: 0;
  color: $darkGray;
}

.noPaddingLeft {
  padding-left: 0;
}

.dehaCategoryListItem {
  padding-right: 0;
}

.borderLeft {
  border-left: 1px solid $lightGray;
}

.addDropBoxShadow2 {
  @include dropShadow2;
}

.divListWrapperOrientation {
  display: flex;
}

.divider {
  width: 1px;
  background-color: $lightGray;
}
.categoryListSubcategories {
  padding: 0;
}

.paddingTopAndBottomOneRem {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.paddingTopAndBottomHalfRem {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.noPadding {
  padding: 0;
}

.categoryListPropertiesMobile {
  width: 100%;
  height: 100%;
  background-color: $white;
}

.listItemMobile {
  padding: 0.625rem 1.5rem;
}

.paddingLeftHalfRem {
  padding-left: 0.5rem;
}

.fontWeight400 :global .MuiTypography-root {
  font-weight: 400;
}

.noPaddingRight {
  padding-right: 0;
}

.boxWrapperTabs {
  border: none;
  @include dropShadow2;
}

.boxWrapperTabs :global .MuiButtonBase-root {
  color: $darkGray;
  text-transform: inherit;
  font-weight: 700;
}

.boxWrapperTabs :global .MuiTabs-indicator {
  display: flex;
  justify-content: center;
  background-color: transparent;
}

.boxWrapperTabs :global .MuiTabs-indicator > span {
  $textLengthLagersortimentskatalog: 9.313rem; //149px;
  max-width: $textLengthLagersortimentskatalog;
  width: 100%;
  background-color: $darkGray;
}

.tabPanel {
  padding: 0;
  overflow: auto;
}

.listItemMobileAndTablet {
  padding-bottom: 0;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
}

.divWrapperMobile {
  width: 100%;
  background-color: $white;
}

.backButton {
  color: $darkGray;
  margin-left: 0.75rem; //12px;
  border: none;
  &:hover {
    border: none;
    box-shadow: none;
  }
}

.divHoldingBackButton {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 1.5rem;
  overflow: hidden;
  clear: both;
  @include dropShadow2;
}

.mobileWidth {
  width: 100%;
}

.divBlur {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.marginTopOneAndHalfRem {
  margin-top: 1.5rem;
}

.marginLeftHalfRem {
  margin-left: 0.5rem;
}
.customdivListWrapper {
  background-color: $white;
  position: fixed;
  top: 10.75rem;
}
.homeListWrapper {
  width: 16rem;
  ul {
    width: auto;
  }
  li {
    border-bottom: 1px solid #e7e7e7;
  }
}
