@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.mapContolBtn {
  position: absolute;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border: 1px solid #3f3f3e;
  border-radius: 3px;
  padding: 6px;
  box-sizing: border-box;
  background: #ffffff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
