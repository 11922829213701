@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';

.wrapper {
  box-sizing: border-box;
  height: 100%;
  background: $white;
  border-radius: 0.25rem;
  position: relative;
  cursor: pointer;

  @include dropShadow1;

  .suppliersOverviewFooter {
    padding: 1rem;
    display: flex;
    justify-content: center;

    .footerDropdownTitleWrapper {
      margin-right: 0.5rem;
    }
  }

  .suppliersOverviewTags {
    display: flex;
    gap: 1rem;
    align-content: flex-start;
    justify-content: flex-end;
    padding: 1rem 1rem 0 1rem;
    min-height: 1rem;

    .voltimumIcon {
      height: 0.875rem;
      width: 2.875rem;
    }
  }

  .suppliersOverviewImg {
    padding: 1rem 2rem;
    width: 100%;
    box-sizing: border-box;
    height: 7.375rem;
    border-bottom: 1px solid $lightGray;

    @include below-sm {
      padding: 1rem;
    }

    .suppliersOverviewImgContainer {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

.popover {
  padding: 1rem 1rem calc(1rem + var(--obeta-safe-area-bottom-root)) 1rem;
  @include rowGap(1rem);
}
