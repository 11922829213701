@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.selectionBar {
  align-items: center;
  background-color: $white;
  bottom: 0;
  box-shadow: 0 0 15px 0 $black15pct;
  box-sizing: border-box;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  left: 0;
  margin: 0;
  padding: 0 1rem;
  width: 100%;
  right: 0;
  &Content {
    width: 100%;
    display: flex;
    align-items: center;
    @include lg {
      width: 78.125rem;
    }
  }
}

.selectionBarContainerWrapper {
  bottom: 0;
  position: sticky;
  z-index: 500;
}
