@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
/* slideTransition.scss */

/**
 * Stack item is either "pushed" or "popped"
 * data-direction attr store current state
   PUSH: transition new element from left to right
   POP: transition old element from right to left
*/

.transtionGroup {
  height: 100%;
}

.transitionGroupWrap {
  /**
    next js throws error if you use :global outside of selector 
  */
  :global {
    [data-direction='push'] {
      & > .push-enter,
      & > .pop-enter {
        z-index: 100;
        transform: translate(100%);
      }
    }

    [data-active-direction='push'] {
      & > .push-enter-active,
      & > .pop-enter-active {
        transition: transform #{$slideTransitionTimeout}ms ease-in-out;
        transform: translate(0);
      }
    }

    [data-direction='pop'] {
      & > .push-exit,
      & > .pop-exit {
        z-index: 100;
        transform: translateX(0);
      }
    }

    [data-active-direction='pop'] {
      & > .push-exit-active,
      & > .pop-exit-active {
        transition: all #{$slideTransitionTimeout}ms ease-in-out;
        transform: translateX(100%);
      }
    }
  }
}
