@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.wrapperIconAndText {
  display: flex;
}

.infoIcon {
  color: $white;
  width: 1.25rem;
  height: 1.25rem;
}

.text {
  margin-left: 0.5rem;
}

.setCookieButton {
  margin-top: 0.5rem;
  float: right;
}
