@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.root {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  /** add small padding to make avoid clipping of shadow */
  padding: 0.0625rem;
  :global {
    .swiper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .swiper-pagination-bullet {
      margin: 0;
    }

    .swiper-pagination-bullets {
      position: static;
      order: 100;
      margin-top: 1rem;
    }

    .swiper-pagination-bullet {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0.5rem;
      background-color: $lightGray;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background-color: $primaryColor;
      }
    }
  }
}
