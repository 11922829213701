@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.active {
  background-color: $greenLight;
}

.expired {
  background-color: $red100pct;
}

.expiresSoon {
  background-color: $orange;
}

.flag {
  border-radius: 0.125rem;
  color: $white;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.125rem 0.25rem;
  width: fit-content;
}

.new {
  background-color: $green;
}
