@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import '../../../../../../libs/assets/theme/mixins.scss';
@import '../../../../../../libs/assets/theme/coreVariablesV2';

.radioButton {
  padding: 0;
  margin-right: 0.5rem;
  @include below-lg() {
    height: 2rem;
    width: 2rem;
    margin: -0.5rem 0.125rem -0.5rem -0.375rem;

    &.touch {
      margin: -0.5rem 0.25rem -0.5rem -0.25rem;
    }

    &.createUserContextRadioDropdown {
      margin: -0.5rem 0.25rem -0.5rem -0.25rem;
    }
  }
}

.radioLine {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .tooltipIcon {
    height: 1rem;
    width: 1rem;
  }
}

.tooltipIcon {
  height: 1rem;
  width: 1rem;
  * {
    color: $darkGray !important;
  }
}

.fullWidth {
  width: 100%;
}

.dropdown {
  &.dropdownPaper {
    width: 100%;
    justify-content: start;
  }
}

.formControlCheckbox {
  margin-right: 0.5rem;
}

.formControlLabel {
  margin-bottom: 1rem;
}

.formControlLabelVariant {
  margin-bottom: 0;
  margin-right: 0;
}

.radioGroupStyles {
  display: flex;
  justify-content: space-between;

  @include below-sm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.checkBoxesAsColumn {
  display: flex;
  flex-direction: column;
  margin-left: -0.5rem;
  &.checkBoxesAsColumnSize {
    width: 50%;
  }
}

.selectBoxPricePermission {
  margin-top: -2rem; // -32 px
  margin-left: 2.5rem; // 40px;
}

.selectBoxPricePermissionDesktop {
  margin-top: -1.625rem; // -26 px
  margin-left: 1.875rem; // 30 px
}

.columnMinSize {
  @include below-md {
    min-width: 18.75rem; //300px;
  }
  min-width: 31.25rem; //500px
}

.dividerMargin {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.selectRoot {
  justify-content: space-between !important;
}

.selectTooltip {
  margin-left: 0.5rem;
}
