@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/animations';

.logoSection {
  padding-top: 0.5rem;

  .imageOuter {
    position: relative;

    .editUserPhotoButton,
    .deleteUserPhotoButton {
      position: absolute;
      background: $darkGray;
      color: $white;
    }

    .editUserPhotoButton {
      bottom: 2.8125rem;
      right: -0.5rem;
      z-index: 100;
    }

    .deleteUserPhotoButton {
      right: 27px;
      bottom: 1px;
      z-index: 100;
    }
  }

  .image {
    border-radius: 50%;
    background: $darkGray21K;
    position: relative;
    width: 12.5rem;
    height: 12.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;

    .imageContent {
      position: absolute;
      background: $darkGray21K;
      background-size: cover;

      &.dummyImage {
        background-size: contain !important;
        top: 10px;
      }
    }
  }

  .noScreenshotIndicator {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 5px solid $red100pct;
    border-radius: 50%;
    @extend .blink;
    animation-duration: 2s;
  }
}

.loadingContent {
  background-color: $white;
  overflow: auto;
  width: 100%;
  position: relative;
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  align-items: center;
  box-shadow: 0 16px 20px rgba(0, 0, 0, 0.4);

  .progressSpin {
    width: 2rem !important;
    height: 2rem !important;
    color: $red100pct;
  }
}
