@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 1rem 1.5rem 1rem;
  @include sm {
    margin: 0 1.5rem 1.5rem 1.5rem;
  }
  @include lg {
    margin: 0 0rem 2rem 0rem;
  }
}
