@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  margin-top: 2rem;
}

.categories {
  display: grid;
  justify-content: space-between;

  @include sm {
    grid-template-columns: repeat(8, max-content);
    gap: 1rem;
  }

  @include md {
    grid-template-columns: repeat(12, max-content);
  }

  @include lg {
    gap: 1.875rem 0;
  }
}

.category {
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.resetSectionMarginAndPadding {
  margin-top: 0;
  // Overwrites the .section-wrapper styles w/in Section.module.scss
  padding-top: 0 !important;
}

.devider {
  border-color: $lightGray;
  margin-bottom: 2rem;
}

.section {
  margin-bottom: 4.5rem;

  @include lg {
    margin-bottom: 6.5rem;
  }
}

.each-category {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  position: relative;
  display: inline-block;
}

.removeImmediatelyAvailableFilter {
  margin-top: 2rem;
}
