@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  @include articleDetailLeftRightPadding;
}

.data {
  min-width: 0;
  max-width: 100%;
}

.dataLg {
  display: grid;
  grid-gap: 1.875rem;
  grid-template-columns: 1fr 1fr;
}

.oneCol {
  @include lg {
    grid-template-columns: 1fr;
  }
}

.comparableItemsButton {
  width: fit-content;
  justify-self: flex-end;
  @include md {
    justify-self: flex-end;
  }
}

.checkbox {
  transform: scale(1);
  padding: 0.5rem;
}

.label {
  font: inherit;
}

.showLessIcon {
  transform: rotate(180deg);
}
.btn-wrapper {
  width: fit-content;
  justify-self: flex-end;
  @include below-sm {
    margin-top: 1rem;
    justify-self: center;
  }
}
