@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.selectionBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &Cart {
    justify-content: flex-end;
  }
  &Item {
    display: flex;
    align-items: center;
    width: fit-content;
    column-gap: 1rem;
  }
}
