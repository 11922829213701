@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.dialog,
.dialogSubmitted {
  :global .MuiPaper-root {
    margin: 0;
    padding-bottom: 1rem;
  }
}

.dialog {
  :global .MuiPaper-root {
    width: 28.75rem;
  }
}

.dialogSubmitted {
  :global .MuiPaper-root {
    width: 18.75rem;
  }
}
.select {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  > input {
    padding-left: 0.75rem;
  }
}

.submitted {
  justify-content: center;
}
