@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.background {
  @include grayFullWidthBg;
}

.content {
  padding-top: 1rem;
}

.shoppingcartdetails {
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.additionalScrollPadding {
    padding-bottom: 3.5rem;
  }
}
.divider {
  @include below-sm {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
  @include above-sm {
    margin: 1rem 0;
  }
}

.toolbar {
  justify-content: space-between;
  align-items: center;
  min-height: unset;
  height: 1.5rem;
  @include lg {
    height: 2rem;
  }
}

.pageGrid {
  // We only require a 1rem gap from divider to header (and summary box in desktop view), MUI sets 2rem by default
  :global .MuiGrid-root:nth-child(3) {
    padding-top: 1rem;
  }
  @include lg {
    margin-bottom: 3.5rem;
  }
}

.actionButtonBox {
  display: flex;

  :global .MuiButton-root {
    min-width: 2rem;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    @include lg {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }

  :global .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0;
    @include sm {
      margin-right: 0.5rem;
    }
  }
}

.printAndExportButtonForMobile {
  border-color: $lightGray;
  &:hover {
    border-color: $darkGray;
  }
  &:active {
    border-color: $lightGray;
    color: $darkGray;
  }
  &.Mui-disabled {
    border-color: $lightGray;
    color: $darkGray;
  }
}

.marginBottomOneRem {
  margin-bottom: 1rem;
}

.paddingTopOneAndHalfRem {
  padding-top: 1.5rem !important;
}

.wrapperDeadProductCard {
  width: 100%;
  margin-bottom: 1rem;
}

.alertBox {
  border-left: 0.375rem solid;
  border-radius: unset;
  border-color: $red100pct;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 12px;
  padding: 16px;
  @include dropShadow1;
}
.alertBoxHead {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
}

.titleContainerClassName {
  display: flex;
  gap: 0.5rem;
}
