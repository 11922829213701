@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.dropdown {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  &Element {
    align-items: center;
    display: flex;
    height: 100% !important;
  }
  &JustifyBetween {
    justify-content: space-between;
  }
}

.menuItem {
  width: 100%;
  &Title {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }
}
