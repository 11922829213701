@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.registeredOuter {
  background: $green;
  padding: 2.5rem 9rem;

  &.compact {
    padding: 2.5rem 1rem;
  }
}

.registeredInner {
  background: $white;
  padding: 2.5rem 1rem 2rem;
  border-radius: 8px;
}

.checkmarkCircle {
  color: $green;
  fill: $green;
}

.successText {
  color: $green;
  font-size: 1.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 900;
  line-height: 1.75rem;
  text-align: center;
}

.infoText {
  font-size: 1rem;
  text-align: center;
}
