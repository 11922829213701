@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.selectionBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &Cart {
    justify-content: flex-end;
  }
  &Item {
    display: flex;
    align-items: center;
    width: fit-content;
  }
}

.idsButton {
  margin-right: 1rem;
}
