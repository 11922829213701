@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.back {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.card {
  @include container;
  align-items: stretch;
  box-sizing: border-box;
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
}

.flags {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}

.info {
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &ContentNext {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include sm {
      align-items: center;
      flex-direction: row;
    }
    &CheckboxAndSelect {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
    }
  }
  &Title {
    margin-top: 0.5rem;
  }
  @include sm {
    gap: 1rem;
  }
}

.titleAndPrices {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &Info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    @include sm {
      flex-direction: row;
      gap: 1rem;
    }
    &Item {
      align-items: center;
      display: flex;
      gap: 0.55rem;
    }
  }
  @include lg {
    flex-direction: row;
    justify-content: space-between;
  }
}

.titleAndEditButtonWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @include sm {
    align-items: center;
  }
  @include lg {
    gap: 0.5rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  &:global(.MuiDivider-root) {
    align-self: stretch;
  }
}

.shippingSectionTitle {
  margin-top: 0.5rem;
}
