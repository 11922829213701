@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.dialog {
  :global .MuiPaper-root {
    width: 18.75rem;
    margin: 0;
    padding-bottom: 1rem;
  }
}

.dialogActions {
  display: flex;
  justify-content: space-around;
  padding: 1rem 1.5rem 0;
}

.dialogContent {
  padding: 0 1rem 1rem;
  text-align: center;
}

.dialogTitle {
  text-align: center;
}

.divider {
  margin-left: 1rem;
  margin-right: 1rem;
}
