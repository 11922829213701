@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import '../../../../../libs/assets/theme/mixins.scss';

.datePicker {
  max-height: 21.25rem;
  width: 19.5rem;
  :global .MuiCalendarPicker-root {
    margin-right: 1rem;
    width: 19rem;
    :global .PrivatePickersFadeTransitionGroup-root {
      margin-left: 0.25rem;
    }
  }
  :global .MuiYearPicker-root {
    margin-left: 1.25rem;
  }
}

.datepicker {
  @include below-lg {
    width: 100%;
  }
  @include lg {
    width: 20.125rem;
  }
}
.textfield {
  width: 20rem;
  height: 3rem;
  padding: 0 0.25rem 0 0;

  legend {
    width: 0;
  }
}

.outlinedInput {
  height: 3rem;
  width: 100%;
}
