@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.mapControlsWrapper {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: flex-end;
  margin: 0 1rem 1.5rem 1rem;
  @include sm {
    margin: 0 1.5rem 1.5rem 1.5rem;
  }
  @include lg {
    margin: 0 0rem 2rem 0rem;
  }

  @include below-sm {
    flex-direction: column;
  }

  .searchWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .distanceSliderWrapper {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include below-sm {
      width: 100%;
    }
  }
}
