@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.mobileSideBarFooterWrapper {
  background: $white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  padding: 0.75rem 1.5rem calc(0.75rem + var(--obeta-safe-area-bottom-root, 0)) 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .abortButton {
    flex-grow: 0;
  }

  .applyButton {
    flex-grow: 1;
  }
}
