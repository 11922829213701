@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.dialog {
  :global .MuiPaper-root {
    max-width: 18.75rem;
    text-align: center;
    > * {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  :global .MuiDialogContent-root {
    padding-bottom: 1rem;
  }
}

.divider {
  margin-top: 1rem;
}

.icon {
  &.checkIcon {
    * {
      color: $green;
    }
  }

  &.cancelIcon {
    * {
      color: $primaryRed;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem 1rem 1.5rem;
}
