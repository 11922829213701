@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import './mixins.scss';

.layout {
  width: 100%;
  min-width: 0;
  // width: fit-content;
  max-width: 100%;
  @include cardContent;
  display: flex;
  flex-direction: column;
  @include rowGap(1rem);
  height: 100%;
}

.header {
  // Make sure header takes all empty space
  flex: 100;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0;
}

.topAdornments {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.divider {
  margin-top: 1rem;
}

.withLabels {
  grid-template-columns: auto auto 1fr;
  display: grid;
  grid-gap: 1rem;
}
.stacked {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .labelwrap {
    min-height: 1rem;
    > div {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
}

.actionsWrapper {
  margin-bottom: 0.5rem;
  &:empty {
    display: none;
  }
}
.verticalCardLayoutBottom {
  input {
    min-height: 2rem;
  }
}
