@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.offerList {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  :global(.ReactVirtualized__Grid) {
    overflow: visible !important; // https://github.com/bvaughn/react-virtualized/issues/876#issuecomment-367029529
  }
  :global(.ReactVirtualized__Grid__innerScrollContainer) {
    overflow: visible !important;
  }
}

.appBarWrapper {
  padding-bottom: 1.5rem;
  @include below-sm {
    padding-bottom: 1rem;
  }
}

.title {
  margin-bottom: 2rem;
}

.noOffersBoxWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 9rem;
  padding-bottom: 9rem;
  @include below-sm {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
}

.row {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.row > * {
  flex: 1;
}

.noOffers {
  display: flex;
  flex-direction: column;
  align-items: center;
}
