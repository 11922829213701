@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

$locationIconWidth: 2.75rem;

.field {
  width: 100%;
}

.inputRoot {
  padding-left: 0.75rem;
}

.input {
  height: 1.25rem;
}

.adornment {
  margin-right: 0.25rem;
}

.searchIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: #929292;
}

.iconWrapper {
  display: flex;
  width: $locationIconWidth;

  .locationIcon {
    color: $darkGray34K;
  }
}

.contentWrapper {
  width: calc(100% - $locationIconWidth);
  word-wrap: break-word;

  .secondaryText {
    color: $darkGray55K;
  }
}
