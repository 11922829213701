@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.center {
  display: flex;
  gap: 0.3125rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 45.625rem;
}

.centerTabletWide {
  display: flex;
  gap: 0.3125rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 44.5rem;
}

.centerMobile {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  margin-left: auto;
  margin-right: auto;
  align-items: stretch;
  width: 100%;
  max-width: 45.625rem;
}

.barContainer {
  max-width: 45.625rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0.25rem;
  margin-left: auto;
  margin-right: auto;
}

.barContainerTabletWide {
  max-width: 44.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0.25rem;
  margin-left: auto;
  margin-right: auto;
}

.barContainerMobile {
  width: 100%;
  display: grid;
  column-gap: 0.25rem;
  margin-left: auto;
  margin-right: auto;
}
.activeFirst {
  grid-template-columns: 2fr 1fr 1fr;
}

.activeSecond {
  grid-template-columns: 1fr 2fr 1fr;
}

.activeThird {
  grid-template-columns: 1fr 1fr 2fr;
}

.TextField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 1.5rem;
  max-width: 21.875rem;
}
.TextFieldMobileSmall {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 1rem;
  width: 100% !important;
}
.TextFieldMobileSmall2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1rem;
  align-items: stretch;
  width: 100% !important;
  padding-bottom: 1.5rem;
}
.TextFieldMobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 1rem;
  width: 100% !important;
}
.formContainer {
  display: grid;
  grid-template-columns: repeat(2, 24rem);
  grid-template-rows: auto;
  max-width: 45.625rem;
  margin: 0 0 0 0;
}
.formContainerMobile {
  display: grid;
  grid-template-columns: repeat(2, 100%);
  grid-template-rows: auto;
  width: 100% !important;
  margin-top: 1rem;
}

.mobile {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-template-rows: auto auto;
  margin: 0 auto;
}
.noPadding {
  padding: 0;
}

.fullWidth {
  width: 100%;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.5rem;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.activeTab {
  color: $red100pct;
}
.inactiveTab {
  color: $darkGray55K;
}

.previousTab {
  color: $darkGray;
}
.lastTab {
  color: $darkGray34K;
}
.size {
  width: 3.875rem;
  height: 1rem;
  flex-grow: 0;
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: left;
  color: $darkGray55K;
  padding: 0.5rem 0 0.5rem 0;
}
.helperTextIcon {
  margin-right: 0.5rem;
}
.helperTextContainer {
  margin: 0.25rem 0 0 0;
}
