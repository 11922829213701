@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins';

.header {
  width: 100%;
  height: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  @include lg {
    height: 1.25rem;
  }
}

.checkbox {
  align-items: center;
  display: flex;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
}

.meatballs {
  height: 1.25rem;
}
