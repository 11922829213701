@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  display: flex;
  flex-direction: column;
  @include rowGap(1rem);
  height: 100%;
  background-color: $white;
}
