@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/mixins.scss';

.mapWrapper {
  box-sizing: border-box;
  height: 100%;
  margin: 0 1rem 1.5rem 1rem;
  @include sm {
    margin: 0 1.5rem 1.5rem 1.5rem;
  }
  @include lg {
    margin: 0 0 2rem 0;
  }
  @include dropShadow1;
  border-radius: 4px;
}
