@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

/**
  same block in ProductsCarousels.module.scss and SearchPage.module.scss
*/

.filter {
  width: 100%;
  margin-bottom: 3.5rem;
}

.templatesList {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;

  .gridItem {
    @include below-md {
      max-width: 100% !important;
    }
  }
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
}

.toolbar {
  padding: 0 !important;
  justify-content: space-between;
}

.skeletonSearchBar {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
}

.skeletonFilters {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
}

.ellipsis {
  border-radius: 0.25rem;
  line-height: 2rem;
  height: 2rem;
  width: 2.75rem;
  border: 1px solid #0000003b;
}

.selected {
  background-color: $darkGray !important;
  color: #fff;
}

.disabled {
  opacity: 1 !important;
  color: $darkGray55K;
}

.paginationWrapper {
  padding-top: 1rem;
  height: 2rem;
  display: flex;
  justify-content: center;
}
