@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.section-wrapper {
  // This may get overwritten by the .resetSectionMarginAndPadding styles w/in SearchNoResults.module.scss
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @include lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.section-header {
  margin: 0 0 1.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
  flex-wrap: wrap;
}

.section-title {
  z-index: 1;
}

.right-adornment {
  z-index: 1;
  cursor: pointer;
}

.center-position {
  justify-content: center;
}

.start-position {
  justify-content: space-between;
}
.section-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 0.656rem;
  }
}

.both {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
