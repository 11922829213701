@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/mixins.scss';

$headerHeightMobile: $productStickyBoxHeightMobile;
$headerHeight: $productStickyBoxHeight;

.product {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
}

.root {
  @include dropShadow2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  min-height: $headerHeight;
  display: flex;
  align-items: center;
}

.wrapper {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: $shopMaxWidth;
  padding: 0.875rem 1rem;
}

.mobile {
  .wrapper {
    grid-template-columns: 1fr auto;
    padding: 0.875rem 2rem;
    .actions {
      grid-column: 1 / span2;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      > *:first-child {
        flex: 1;
      }
    }
  }
}

.rightBlock {
  display: flex;
  @include columnGap(1rem);
}

.prices {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.oldPrice {
  text-align: end;
}
.actions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
}

.productTitle {
  height: $headerHeight;
  overflow: hidden;
}

:export {
  headerHeightMobile: $headerHeightMobile;
  headerHeight: $headerHeight;
}
