@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

/**
  should we reuse this?
  same block in ProductsCarousels.module.scss

*/
.background {
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.root {
  max-width: $shopMaxWidth;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.breadcrumbs {
  height: 4.25rem;
  position: relative;
  z-index: 1;
}

.breadcrumbsWrapper {
  position: relative;
  margin-bottom: 1.5rem;
}

.breadcrumbsBackground {
  background-color: #fff;
  position: absolute;
  width: 100vw;
  height: 100%;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.body {
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 100;
  min-width: 0;
  @include rowGap(1.5rem);
  overflow: visible; //keep this visible or it will cut the shadows of card hover effects
  @media (max-width: ($shopMaxWidth)) {
    //todo quickfix for screens under 78rem
    //how to allow the shadow to be visible even when parent container is overflow hidden?!
    overflow: hidden;
  }
}
