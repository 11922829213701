@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.container {
  display: flex;
  :global .MuiStepper-root {
    width: 100%;
  }
}

.customStepIcon {
  height: 1.375rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    .customStepIconCircle {
      background-color: $green;
    }
  }

  &:not(.active) {
    .customStepIconCircle {
      border: 1px solid $darkGray34K;
    }
  }

  &.completed {
    .customStepIconCircle {
      border: unset;
      height: 2rem;
      width: 2rem;
    }
  }

  .customStepIconCircle {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.stepLabel {
  line-height: 1.43;
  :global .MuiStepLabel-label {
    margin-top: 0.5rem;
  }
}

.active {
  :global .MuiStepLabel-label {
    color: $green;
  }
}
