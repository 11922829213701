@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.navigationWrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  // height: calc(64px + var(--obeta-safe-area-bottom, 0));
  background-color: $white;
  border: 1px solid $lightGray;

  .navigation {
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding-top: 6px;
    padding-bottom: calc(6px + var(--obeta-safe-area-bottom, 0px));

    .navBtn {
      background: transparent;
      color: $darkGray;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 9rem;
      flex: 1;

      a {
        text-decoration: none;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $darkGray;

        .title {
          font-weight: 500;
          font-size: 0.75rem;
        }

        svg {
          height: 2rem;
          width: 2rem;
          margin: 0;
          color: $darkGray;
        }
      }
    }

    .active {
      .content {
        svg {
          color: $red100pct;
        }

        .title {
          color: $red100pct;
        }
      }
    }
  }
}
