@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.acceptedFiles {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  &White {
    background-color: $white;
    padding: 1rem;
  }
}

.dropzone {
  align-items: center;
  border: dashed 0.0625rem $darkGray55K;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 8.5rem;
  justify-content: center;
  width: 100%;
  &Info {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.error {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  & * {
    color: $red100pct;
  }
  svg {
    height: 1rem;
    width: 1rem;
  }
}
