@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.menuButton {
  align-items: center;
  display: flex;
  grid-gap: 0.5rem;
  height: 100%;
  padding-bottom: 0.5rem;

  &Active {
    box-shadow: inset 0px -0.125rem $red100pct;
  }
  &Enabled {
    cursor: pointer;
  }

  &Title {
    align-items: center;
    display: flex;

    &Active {
      color: $red100pct;
    }
  }
}
