@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.actions {
  display: flex;
  gap: 1rem;
}

.buttonFilters {
  flex-shrink: 0;
}

.clerk {
  align-items: center;
  cursor: pointer;
  display: flex;
  &Name {
    @include sm {
      text-decoration: underline;
    }
    @include lg {
      text-decoration: none;
    }
  }
}

.clerkData {
  display: flex;
  flex-direction: column;
}

.dates {
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
  display: flex;
}

.dateItem {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.details {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  width: 100%;
  &Dates {
    display: flex;
    flex-direction: column;
  }
  &Info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
}

.divider {
  border-color: $lightGray;
  &InclTopSpacing {
    margin-top: 1rem;
  }

  &InclQuarterTopSpacing {
    margin-top: 0.25rem;
  }
}

.filterAndSortWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  &Dropdowns {
    align-items: center;
    display: flex;
    gap: 1rem;
  }
}

.flags {
  display: flex;
  gap: 0.5rem;
  height: fit-content;
}

.infoBottom {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
  &OfferId {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }
}

.infoTop {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include lg {
    gap: 1rem;
  }
}

.moreMenu {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
}

.searchAndFilters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @include sm {
    align-items: center;
    flex-direction: row;
    gap: 1.5rem;
  }
  &MobileTop {
    align-items: center;
    display: flex;
    gap: 1rem;
  }
}

.title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.infoAndAlternativePriceWrapper {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  justify-content: space-between;
  row-gap: 0.875rem;

  @include below-md {
    flex-direction: column;
  }
  @include sm {
    margin-bottom: 0.5rem;
  }
  @include lg {
    margin-bottom: 0.75rem;
  }
}

.titleAndPriceWrapper {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include sm {
    align-items: center;
    flex-direction: row;
  }
  @include sm {
    margin-bottom: 0.75rem;
    -webkit-line-clamp: 1;
  }
  @include md {
    margin-bottom: 0.5rem;
  }
  &Text {
    display: flex;
    gap: 0.25rem;
  }
}

.halfMarginTop {
  margin-top: 0.5rem;
}

.flexDirectionColumn {
  flex-direction: column;
}

.sortWrapper {
  display: flex;
  gap: 1rem;
}

.toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  &Bottom {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    @include sm {
      margin-top: 0;
    }
  }
  &Details {
    margin-bottom: 1rem;
  }
  &Top {
    margin-bottom: 1.5rem;
  }
}

.showDetails {
  padding: 1.25rem 0 1rem 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  &:global(.MuiDivider-root) {
    align-self: stretch;
  }
}
