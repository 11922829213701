@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/mixins.scss';

.locationPageSectionWrapper {
  margin-top: 0;
  margin-bottom: 1.5rem;
  :global {
    .swiper-pagination {
      margin-top: 1rem;
    }
  }

  @include sm {
    margin-bottom: 2rem;
    padding: 0 1.5rem;
    :global {
      .swiper-pagination {
        margin-top: 0;
      }
    }
  }

  @include lg {
    padding: 0;
  }
}

.locationNewsCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cardsWrapper {
  padding: 3px;
}
