@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.checkoutHeader {
  position: relative;
}

.formControl {
  width: 100%;
  button {
    color: white;
  }
}

.formControlLabel {
  height: 3rem;
  @include lg {
    height: 4rem;
  }
  width: 100%;
  margin-right: 0;
}

.selected {
  border: 1px solid $red100pct;
}

.unselected,
.unselectedAndDisabled {
  border: 1px solid $lightGray;
}

.unselected {
  &:hover {
    box-shadow: 0 0 15px 0 $black15pct;
    border: 1px solid $darkGray;
  }
}
