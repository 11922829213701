@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.root {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.paginationItem {
  box-sizing: border-box;
  background-color: #fff;
  height: 2rem;
  width: 2rem;
  border-color: $lightGray;
  color: $darkGray;
}

.ellipsis {
  border-radius: 0.25rem;
  line-height: 2rem;
  height: 2rem;
  width: 2.75rem;
}

.selected {
  background-color: $darkGray !important;
  color: #fff;
}

.disabled {
  opacity: 1 !important;
  color: $darkGray55K;
}
