@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.mainInfo {
  width: 100%;
  display: grid;
  grid-template-areas:
    'Gallery'
    'Price'
    'ProductProperties';
  grid-gap: 1.75rem;

  @include sm {
    grid-template-areas:
      'Gallery Gallery'
      'ProductProperties Price';
    grid-template-columns: 1fr 1fr;
    margin-top: 1.5rem;
    grid-gap: 1.5rem;
  }
  @include md {
    grid-auto-flow: unset;
    grid-template-areas: 'Gallery ProductProperties Price';
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.priceWrap {
  position: relative;
  grid-area: Price;

  @include below-sm {
    &:after {
      //this makes the background go to the edgeds
      content: '';
      position: absolute;
      background-color: $lightGray5K;
      left: -$shopXsThreshold;
      top: 0;
      bottom: 0;
      width: 100vw;
      z-index: 0;
    }
  }
  @include sm {
    margin-left: 0;
    margin-right: 0;
  }
  @include md {
    margin: 0;
  }

  @include lg {
    margin: 0;
  }
}
