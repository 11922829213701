@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 0.5rem;
}

.circle {
  width: 0.417rem;
  height: 0.417rem;
  border-radius: 100%;
  background-color: #c4c4c4;
  animation-fill-mode: both;

  // "gap" prop leads to visual inconsistency: gap between 2 and 3 circle is bigger than between 1 and 2.
  margin-right: 0.2rem;
}

.animation {
  @include indicatorKeyframes('grow', 0.67, 1.33, 1);
}

.circle1 {
  animation-delay: 0s;
}

.circle2 {
  animation-delay: 0.3s;
}

.circle3 {
  animation-delay: 0.6s;
}
