@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.maxValueLine {
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0 0 1rem;
  align-items: center;

  &.alignGap {
    margin-top: -0.5rem;
  }
}

.inputWithTooltip {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}

.maxValueInput {
  width: 100%;
}

.titleWithTooltip {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.radioContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 0.75rem;
}

.selectedEntities {
  margin: -0.5rem 0 0 1rem;
}
