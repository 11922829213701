@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @include below-sm {
    margin-top: 2rem;
  }
}
.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.additionalText {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  &Input {
    height: 5.75rem;
    :global .MuiOutlinedInput-root {
      height: 5.75rem;
    }
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include lg {
    margin-top: 2.5rem;
  }
}
.requiredText {
  display: flex;
  justify-content: flex-end;
}

.uploadedFileListing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 36px;
  align-self: stretch;
  flex-grow: 0;
  align-items: center;
  padding: 0.5rem;
}

.uploadWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.iconAndTitleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.blackLink {
  color: $darkGray;
  text-decoration: underline;
}
