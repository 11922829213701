@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.warningContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}

.warningIcon {
  color: #e2061b;
  margin-right: 0.25rem;
}

.warningText {
  color: #e2061b;
}
