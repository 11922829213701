@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin: 2rem 1.5rem 1.5rem;
  padding: 1.5rem;
  background-color: $lightGray5K;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nameSection,
.addressSection {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
}

.addressSection {
  gap: 0.5rem;
  padding-bottom: 0.25rem;
}

.increasedPaddingBottom {
  padding-bottom: 1.5rem;
}
.nameContent {
  padding-bottom: 0.5rem;
}
.formHelperText {
  &:global(.MuiFormHelperText-root) {
    margin-top: 0.25rem;
  }
}
.options {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 8.75rem;
  justify-content: space-between;
}

.buttonContainer {
  width: fit-content;
  align-self: flex-end;
}

.info {
  text-align: right;
}

.card {
  padding: 0.5rem 1rem 1rem;
  background-color: $white;
  border-radius: 0.25rem;
  width: 100%;
  :global .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
}

.pickUpCard {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contentBox {
  padding-left: 1.75rem;
  display: flex;
  flex-direction: column;
}

.paper {
  border-color: $darkGray;
  justify-content: space-between;
  width: 100%;
}
