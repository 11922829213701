@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dropdown-wrapper {
  z-index: 1;
  padding: 1rem;
  background-color: $white;
  border: 1px solid $lightGray;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 100%;
  height: auto;
  top: $mobileHeaderHeight;
  left: 0;

  @include md {
    position: relative;
    top: 0;
    width: 80%;
    height: auto;
  }

  @include lg {
    width: 100%;
  }
}

.search-input-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.search-icon-wrapper {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $red100pct;
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.search {
  width: 100%;
  position: relative;
}

.autocomplete-root {
  width: 100%;
  height: 100%;
}

.input-root {
  height: 100%;
  > div {
    padding: 0 0.675rem !important;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem 0 0 0.25rem;

    @include md {
      padding: 0.375rem 1rem !important;
    }

    @include lg {
      padding: 0.375rem 1rem !important;
    }
  }
  &.withAdornment {
    > div {
      padding-right: 0.5rem !important;
    }
  }
  :global .MuiOutlinedInput-root {
    align-content: center;
  }
}

.autocomplete {
  &-popper {
    height: auto;
    &-mobile {
      width: 100% !important;
    }
  }

  &-input {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    font-size: 0.875rem;
    @include ellipsis;

    &::placeholder {
      opacity: 0.55;
    }
  }

  &-label {
    color: $darkGray;
  }

  &-listbox {
    padding: 1rem;
    height: 100%;
    @include md {
      height: auto;
      max-height: 38.5rem;
    }
  }
}

.scanner-icon {
  margin-left: 0.5rem;
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  & > svg > path {
    fill: $darkGray55K;
  }
}
