@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/animations.scss';

.onboarding {
  display: flex;
  height: 100%;
  flex-direction: column;

  .slidescontainer {
    height: 100%;
    width: 100%;
    padding-top: 0 !important;
  }

  .actionscontainer {
    padding: 3rem 0.25rem calc(3.125rem + var(--obeta-safe-area-bottom-root)) 0.25rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
  }
}
