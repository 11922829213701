@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins';

.filterIcon {
  flex-shrink: 0;
}

.wrapper,
.sortWrapperMobile {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.wrapper {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.wrapperMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.wrapper,
.searchWrapper {
  gap: 1.5rem;
  justify-content: space-between;
}

.spreadOut {
  justify-content: space-between;
  width: 100%;
}

.searchWrapper {
  flex: 2;
  @include below-sm {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.searchAndSortWrapperMobile {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}

.sortWrapperMobile {
  width: 100%;
}

.formControlLabel {
  min-width: fit-content;
}

.selectAllCheckbox {
  padding: 0;
  margin-right: 0.25rem;
  font-size: 2.5rem;
  :global .MuiSvgIcon {
    font-size: 1.5rem;
    @include lg {
      font-size: 1.25rem;
    }
  }
}

.createNew {
  min-width: fit-content;
}

.paper {
  background-color: $lightGray5K;
}
