@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.continueButtonWrapper {
  width: 100%;
  padding: 0 4rem;

  .continueButton {
    width: 37.625rem;
    margin-top: 2rem;
    margin-bottom: 4rem;

    @include below-md {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  @include below-md {
    padding: 0 1.5rem;
  }
}
