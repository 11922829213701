@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.statusBubble {
  width: 0.625rem;
  height: 0.625rem;
  min-width: 0.625rem;
  min-height: 0.625rem;
  border-radius: 0.625rem;
  background-color: $red100pct;
}

.statusSuccess {
  background-color: $green;
}
