@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.wrapper {
  margin-top: 1rem;

  * {
    box-sizing: border-box;
  }

  .progressbarContainer {
    border: 0.125rem solid $orange34pct;
    border-radius: 4px;
    box-sizing: border-box;

    :global {
      .RSPBprogressBar {
        background-color: $orange34pct;
        border-radius: 0px;
      }
      .RSPBprogression {
        background: $orange;
        border-radius: 0.125rem;
      }
    }
  }

  .step-item {
    width: 1.125rem;
    height: 1.125rem;
    background: $white;
    border: 0.25rem solid $green;
    border-radius: 50%;
    &-not-accomplished {
      border: 0.25rem solid $orange34pct;
    }
  }

  &.success {
    .progressbarContainer {
      border-color: #27ae6057;
      :global {
        .RSPBprogressBar {
          background-color: #27ae6057;
        }
        .RSPBprogression {
          background: $green;
        }
      }
    }
    .step-item {
      &-not-accomplished {
        border: 0.25rem solid #27ae6057;
      }
    }
  }
}

.status {
  margin-bottom: 1rem;
}

.benefits {
  margin-top: 0.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reward {
  margin-top: 0.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  &-image {
    width: 3.375rem;
    height: 4rem;
    margin-bottom: 0.8125rem;
    object-fit: contain;
  }
  &-not-accomplished {
    opacity: 0.5;
  }
}
