@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.back {
  width: fit-content;
}

.background {
  @include grayFullWidthBg;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.shoppingCartCheckout {
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.informationContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  :global .MuiCardContent-root {
    &:last-child {
      padding-bottom: 0;
    }
  }
  padding: 1rem;

  @include lg {
    gap: 1.5rem;
    padding: 1.5rem;
  }
}

.orderDeclarations {
  padding-top: 0.75rem;
  padding-bottom: 1rem;

  @include sm {
    padding-top: 1.5rem;
  }
}

.gridHoldingSummary {
  padding-top: 1rem;
  @include lg {
    padding-top: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 1000; /* Adjust the z-index based on your needs */
  display: flex;
  justify-content: center;
  align-items: center;
}

.withOverlay {
  z-index: 1001;
}
