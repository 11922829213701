@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.container {
  align-items: center;
  background-color: $lightGray8K;
  border-radius: 0.25rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  overflow: hidden;
  padding: 0.5rem;
  width: 20rem;
  margin-bottom: 0.75rem;
}

.icon {
  flex-shrink: 0;
}

.info {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
}

.delete {
  flex-shrink: 0;
}

.name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
