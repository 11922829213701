@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.gridContainer {
  padding-bottom: 1rem;
}

.headerBox {
  display: flex;
  justify-content: space-between;
}

.storeSelectionButton {
  padding-right: 0;
}

.addressManagementButton {
  padding-right: 0;
}

.specialTourBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.specialTourAsNormalTourButton {
  padding-right: 0;
}

.pickupDivider,
.deliveryDivider {
  padding-bottom: 0.75rem;
  @include md {
    padding-bottom: 1.5rem;
  }
}

.optionsGrid {
  padding-top: 0.75rem;

  @include md {
    padding-top: 1.5rem;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.days,
.times {
  display: flex;
  flex-direction: column;
}

.times {
  margin-left: 0.25rem;
}
