@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.subtitle {
  margin-bottom: 0.5rem;
}

.navMenu {
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  height: 100%;
  margin-bottom: 1.5rem;
  white-space: nowrap;
  overflow-x: auto;
}

.menuItems {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.menuButton {
  width: auto;
  padding-right: 1.5rem;
}

.submenuItem {
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 2.25rem;
}

.submenuBack {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 1rem;
}

.submenuPopoverMobile {
  height: 100%;
  padding: calc(1.5rem + var(--obeta-safe-area-top)) 0.5rem
    calc(1.5rem + var(--obeta-safe-area-bottom)) 0.5rem !important;
}
