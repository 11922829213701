@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.sidebar {
  @include rowGap(0.5rem);
  color: $darkGray;

  @include md {
    margin-right: 2rem;
    width: 16rem;
    min-width: 16rem;
    max-width: 16rem;
  }
}

.mobileRoot {
  position: relative;
  width: 100%;
  background-color: $lightGray5K;
  overflow: hidden;
  padding: var(--obeta-safe-area-top-root) 0 5rem 0;

  .mobileContent {
    height: 100%;
    overflow: auto;
    padding: 1.5rem 1.5rem;
    @include below-sm {
      padding: 1.5rem 1rem;
    }
  }
}

.size,
.availabilitySkeleton {
  margin-left: 0.25rem;
}

.fundingsContainer {
  display: flex;
  align-items: center;
}

.fundingsLink {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.fundingsInfoIcon {
  color: $darkGray;
  height: 1rem;
  width: 1rem;
}
