@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  display: flex;
  @include columnGap(0.25rem);
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
