@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
  @include sm {
    margin: 0 1.5rem;
  }
  @include lg {
    margin: 0;
  }

  .title {
    margin-bottom: 1.5rem;
    @include below-lg {
      margin-bottom: 1rem;
    }
    display: block;
  }
}
