@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.mainWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.mainScreenContent {
  display: flex;
  flex-direction: column;
}

.stackScreen {
  position: static;
  width: auto;
  height: auto;
  overflow: unset;
}
