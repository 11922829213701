@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.wrapper {
  padding-bottom: 0.5rem;
  background: $white;
  border-radius: 0.25rem;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 14.25rem 2fr;
  gap: 1rem;

  @include lg {
    gap: 1.5rem;
  }

  @include below-sm {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .imageSection {
    width: 100%;
    height: 20rem;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    :global {
      .oxomi-catalog-container {
        height: 100%;
        width: 100%;

        .oxomi-border {
          border: none;
          padding: 0;
        }
      }

      .oxomi-catalog {
        height: 100%;
        width: 100%;
        padding: 0;
      }
    }
  }

  .contentSection {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include below-sm {
      gap: 1rem;
      flex-grow: 1;
    }

    .textSection {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .publishedAt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: 1.4px;
      }
    }

    .controlSection {
      display: flex;
      gap: 1rem;

      .previewButton {
        background: $secondaryColor;

        @include below-sm {
          width: 50%;
        }
      }

      .download {
        span {
          margin-top: 2px;
        }

        a {
          all: unset;
        }

        @include below-sm {
          padding: 0.4rem;
          width: 50%;
          span {
            padding: 0;
            margin: 0;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
