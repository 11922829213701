@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.deliveryOptions {
  position: relative;
}
.optionsOuterGrid {
  padding-top: 1rem;
  margin-left: 0;
}

.divider {
  padding-bottom: 0.75rem;
  @include md {
    padding-bottom: 1.5rem;
  }
}

.formControlLabel {
  padding-top: 1rem;
  margin-top: 0.5rem;
  margin-left: 0;

  // TODO Replace with 'our' instance of RadioItem once it has been refactored to be responsive
  :global .MuiRadio-root {
    width: 2.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    @include lg {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

.enlargedRadioIconForTouchDevices {
  :global .MuiSvgIcon-root {
    font-size: 1.5rem;
  }
}

.radioOptionInfoText {
  margin: 0 0 0.5rem 2.25rem;
  padding-bottom: 0.5rem;
}

.success {
  color: $green;
}

.warning {
  color: $orange;
}

.cursorPointer {
  cursor: pointer;
}

.withLink {
  a {
    color: inherit;
    text-decoration: underline;
  }
}
