@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.tableContainer {
  overflow-x: clip;
}

.tableHead {
  background: $darkGray21K;
  color: $darkGray;
  height: 3.5rem;
  max-height: 3.5rem;
}

.tableHead th {
  padding: 0 0 0 1.5rem;
  border-bottom: none;
  &.mobileCell {
    padding-left: 1rem;
  }
  &.mobileCellPaddingRightAndLeftOneRem {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &.tabletCell {
    padding-left: 1rem;
  }
  &.desktopCellPadding {
    @include lg {
      padding: 0 0 0 7.5rem; //120px;
    }
  }
  &.paddingLeftOneRem {
    padding-left: 1rem;
  }
}

.tableBodyRow {
  height: 4.5rem;
  td:last-child {
    padding-left: 0.75rem;
    padding-right: 1rem;
  }
  .desktopCellPadding:last-child {
    @include lg {
      padding-left: 7.5rem; //120px;
      padding-right: 1rem;
    }
  }
  &.mobile {
    height: 3rem;
  }
}

.sortableHead {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  cursor: pointer;
  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: $darkGray55K;
  }

  &.increasedTouchArea {
    gap: 0;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.5rem;
      @include below-lg {
        padding: 0.25rem;
      }
    }
  }
  .rightAligned {
    text-align: right;
    justify-content: flex-end;
  }
}

.receiptHead,
.leftAligned {
  justify-content: unset;
}

.rightAligned {
  text-align: right;
  justify-content: flex-end;
}

.icon {
  display: flex;
  justify-content: flex-end;
  color: $darkGray;
  cursor: pointer;
  a {
    color: $darkGray;
  }
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.cumulatedAmountCell {
  padding-right: 0.75rem;
}

.overdueDate {
  display: flex;
  flex-direction: column;
  * {
    color: $primaryColor;
  }
}

.discountDate {
  * {
    color: $green;
  }
}

.condensedCell {
  padding-right: 0rem;
}

.cell {
  padding-right: 0;
  padding-left: 1rem;
}

.desktopCellPadding {
  @include lg {
    padding: 0 0 0 7.5rem; //120px;
  }
}

.noWrap {
  white-space: nowrap;
}

.receiptContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.noResult {
  margin-top: 1rem;
}

.checkBoxPdfContainer {
  display: flex;
}

.checkBoxHeaderWrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: min-content;
}

.checkBoxHeaderWrapper > th {
  @include sm {
    padding-left: 0;
  }
}

.pdfIcon {
  cursor: pointer;
}

.checkbox {
  align-self: center;
  margin-right: 1rem;

  &.masterCheckBox {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.mobileCell {
  padding-left: 1rem;
}

.textCentered {
  text-align: end;
}

.notPrintableReceipt {
  padding-left: 2.25rem;
}
