@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.mainContainer {
  width: 45.625rem;
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.3125rem 0 rgba(0, 0, 0, 0.1);
  border: none;
  color: $darkGray;

  @include below-md {
    width: 100%;
  }
}

.mainContainerHeight {
  height: 34.25rem;

  @include below-md {
    height: 100%;
  }
}
.mainContainerHeightXpress {
  height: 35.5rem;
}
.mainContainerHeightPromotion {
  height: 59.125rem;
}

.mainContainerHeightGiveAwayPromotion {
  height: 59.125rem;
}

.unsetFixedHeight {
  height: unset;
}

.headerText {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.dataLines {
  margin-bottom: 1.5rem;
  margin-left: 15.28125rem;
  display: block;

  @include below-md {
    margin-left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.dataValues {
  margin-left: 0.5rem;
}

.submitButton {
  width: 37.625rem;
  left: 4rem;
  margin-top: 0.5rem;
  margin-bottom: 4rem;

  @include below-md {
    width: 100%;
    position: static;
  }
}

.animationMainContainer {
  position: static;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  width: 8rem;
}

.circleLoader {
  border: 0.625rem solid rgba(0, 0, 0, 0.2);
  border-left-color: $green;
  -webkit-animation: loader-spin 1s infinite linear;
  animation: loader-spin 1s infinite linear;
}

.circleLoader,
.circleLoader:after {
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.placeholderHeadline {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.placeholderBody {
  margin-bottom: 1.5rem;
  margin-left: 15.28125rem;
}

.withOverlay {
  z-index: 1001;
}
