@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.transitionGroup {
  position: relative;

  :global {
    [data-direction='push'] {
      & > .push-exit,
      & > .pop-exit {
        opacity: 1;
      }
    }

    [data-active-direction='push'] {
      & > .push-exit-active,
      & > .pop-exit-active {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all #{$slideTransitionTimeout}ms ease-in-out;
      }
    }
  }
}
