@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.lineHeight20px {
  line-height: 1.25rem;
}

.marginBottomOneRem {
  margin-bottom: 1rem;
}

.divStoreData {
  display: flex;
  flex-direction: column;
}

.marginBottomHalfRem {
  margin-bottom: 0.5rem;
}

.wrapperStoreData {
  display: flex;
}

.headerT {
  font-weight: 700;
  color: $red100pct;
}

.firstDivStoreData {
  flex-grow: 0.5;
}
