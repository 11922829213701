@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.title {
  @include ellipsis;
}

.nailToEdgeEndIcon {
  margin-left: auto;
}

.darkGrayBorder {
  border-color: $darkGray;
}

.lightGrayBorder {
  border-color: $lightGray;
}

.noMargin {
  margin: 0;
}

.onlyIcon {
  min-width: 0;
  padding: 0.375rem;
}

.dropdownTemplates {
  min-width: 4.313rem;
  @include sm {
    min-width: 6rem;
  }
}

.button {
  &.disabled {
    color: $darkGray34K;
    border-color: $darkGray34K;
    opacity: unset;
  }
}
