@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  display: flex;
  @include columnGap(0.5rem);
  color: $darkGray;
  align-items: center;
  height: 2rem;
}

.pointer {
  cursor: pointer;
}

.title {
  width: auto;
}

.backIcon {
  min-width: 1.25rem;
}
