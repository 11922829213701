@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.downloads {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  @include articleDetailLeftRightPadding;
}

.link {
  display: flex;
  align-items: center;
  padding: 0.5rem;

  .icon {
    color: $darkGray;
  }
}

.table {
  border-radius: 0;
}
