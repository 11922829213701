@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.other-news-block-wrapper {
  padding: 2.5rem 0;
  position: relative;
}

.other-news-block-bg {
  @include grayFullWidthBg;
  z-index: 0;
}

.news-section-no-margin {
  position: relative;
  margin: 0;
}
