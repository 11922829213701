@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.card {
  border: 1px solid $darkGray;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  :global .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
  &.selected {
    border-color: $red100pct;
  }
  &.disabled {
    border-color: $darkGray;
    color: $lightGray5K;
  }
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
}

.contentBox {
  display: flex;
  flex-direction: column;
}

.formControlLabel {
  margin: 0;
  :global .MuiRadio-root {
    padding-right: 0.5rem;
  }
}

.halfLineHeight {
  :global .MuiTypography-root {
    line-height: 0.5rem;
  }
}
