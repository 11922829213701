@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.teasersControlWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;

  .showAllTeasers {
    z-index: 1;
    cursor: pointer;
  }

  .teasersPreviewText {
    color: #ffffff;
  }
}
