@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';
@import '../../../../../libs/assets/theme/mixins.scss';

.mainDiv {
  background-color: $white;
  @include dropShadow1;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 4px;
  @include sm {
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @include above-sm {
    display: flex;
    justify-content: space-between;
  }
  :global .MuiList-root {
    @include below-sm {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.noPaddingTopAndBottomForNav {
  :global .MuiList-root {
    @include below-sm {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.infoUnit {
  display: flex;
  align-items: center;
}

.rectangle {
  width: 1px;
  height: 20px;
  background-color: $lightGray;
}

.marginInfoIcon {
  margin-left: 0.5rem;
  color: $darkGray;
}

.infoUnitMobile {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.headerContainer {
  display: flex;
  justify-content: center;
}

.minContentWidth {
  max-width: min-content;
}

.noPadding {
  padding: 0;
}

.marginBottomHalfRem {
  margin-bottom: 0.5rem;
}

.menuWrapper {
  background-color: $white;
  border-radius: 4px;
  :global .MuiPaper-root {
    background-color: $white;
    padding: 1rem;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  width: 100%;
  min-height: min-content;
}

.creditLimitExtraStyling {
  text-decoration: underline;
}

.underlineText {
  text-decoration: underline;
}

.columnDirection {
  flex-direction: column;
}

.wrapperTabletFirstLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.wrapperTabletSecondLine {
  display: flex;
  justify-content: center;
}

.MarginLeftRightOneAndHalfRem {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
