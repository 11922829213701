@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import '../../../../../../libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.basicStyling {
  background-color: $white;
  @include dropShadow1;
  border-radius: 4px;
}

.radioButton {
  padding: 0;
  margin-right: 0.5rem;
}

.paddingOneRem {
  padding: 1rem;
}

.noMargin {
  margin: 0;
}

.radioGroupStyles {
  display: flex;
  justify-content: space-between;

  @include below-sm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.groupPermissions {
  gap: 1rem;

  &.tablet {
    gap: 1.5rem;
  }

  &.tablet > * {
    flex: 1 1 45%;
  }
}

.groupPermissions > * {
  padding-right: 0.6rem;
}

//Will need a specific margin or gap instead of justify-content: space-between
.twoInARow {
  display: flex;
  justify-content: space-between;
}

.rowMarginDesktop {
  margin-left: 1.75rem;
  margin-bottom: 1rem;
}

.marinRightHalfRem {
  margin-right: 0.5rem;
}

.groupContainer {
  background-color: $white;
  margin-top: 1.5rem;

  &.mobile {
    margin-top: 1rem;
  }

  &.compact {
    margin-top: 1rem;
  }
}

.dividerMargin {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.copyUserPermissions {
  margin: 0;
}

.dropdown {
  &.dropdownPaper {
    width: auto;
    justify-content: start;
  }
}
