@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.label {
  margin-bottom: 0.5rem;
}

.errors {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.error {
  display: flex;
  align-items: center;
}

.requiredField {
  margin-top: 0.5rem;
  text-align: right;
  color: $darkGray55K;
}
