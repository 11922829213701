@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.marginBottom {
  &.xs {
    margin-bottom: 1rem;
  }

  &.md {
    margin-bottom: 1.5rem;
  }

  &.xl {
    margin-bottom: 3rem;
  }
}
