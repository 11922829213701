@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.popover {
  width: 100%;
  padding: 0;
  max-width: unset;
  max-height: unset;
  border-radius: 0;
  top: unset !important; // reset default styles set via style attr
  bottom: 0 !important; // reset default styles set via style attr
  left: 0 !important; // reset default styles set via style attr
  box-shadow: none;
  background-color: transparent;
  @include dropShadow2;
}

.root {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-areas:
    'Input Cancel Accept'
    'Options Options Options';
  row-gap: 0.5rem;
  column-gap: 0.75rem;
  border-radius: 0.25rem;
  @include dropShadow2;
}

.withWarn {
  grid-template-areas:
    'Input Cancel Accept'
    'Warn Warn Warn'
    'Options Options Options';
}

.withKeyboard {
  padding: 1rem 1rem 3.25rem;
}

.textField {
  grid-area: Input;
}

.inputElement {
  min-width: 1rem;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
}

.textFieldAdornment {
  margin-left: 0.5rem;
}

.inputElement,
.textFieldAdornment {
  height: 1.25rem;
  line-height: 1.25rem;
}

.cancel {
  grid-area: Cancel;
}

.accept {
  grid-area: Accept;
}

.warning {
  margin-top: 0.5rem;
  grid-area: Warn;
  padding: 0.5rem 1rem;
  box-shadow: none;
  border-radius: 0.25rem;
  background-color: $orange34pct;
  display: flex;
  align-items: center;
}

.optionsList {
  grid-area: Options;

  :global {
    .mbsc-material {
      &.mbsc-scroller-wheel-group {
        padding: 0;
      }

      &.mbsc-scroller-wheel-item {
        text-align: left;
        padding: 0 1rem;

        & > div {
          height: 100%;
        }
      }

      &.mbsc-scroller-wheel-cont::after,
      &.mbsc-scroller-wheel-cont::before {
        display: none;
      }

      &.mbsc-selected {
        background-color: $lightGray5K;
        font-weight: bold;
      }

      /* Trick to hide empty rows above the minimum value (the topmost row in the scroller list) */
      /* This makes the list n rows shifted to top direction. The topmost row will be considered as selected. */
      &.mbsc-scroller-wheel-cont-inline {
        margin-top: var(--scrollerListOffset);
      }
    }
  }

  .scrollerOptionText {
    font-weight: inherit;
  }
}

.optionsListRow {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 0.25rem;
}
