@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.newsCell {
  display: grid;
  grid-auto-flow: row;
  padding-bottom: 2rem;
  gap: 2rem;

  &.oneColumnGrid {
    grid-template-columns: 1fr;
  }

  &.twoColumnGrid {
    grid-template-columns: 1fr 1fr;
  }

  &.threeColumnGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include below-lg {
    gap: 1rem;
    padding-bottom: 1rem;
  }
}

.newsImageWrapper {
  height: 11.25rem;
}

.newslistitem {
  overflow: hidden;
  flex: 1;
  min-height: 100px;

  &.dummy {
    height: 250px;
  }

  &:first-child {
    margin-right: 0.5rem;
  }

  &:last-child {
    margin-left: 0.5rem;
  }

  @include below-md {
    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
}
