@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.card {
  @include container;
  align-items: stretch;
  box-sizing: border-box;
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
}

.titleAndDataItems {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.titleAndLabelWrapper {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}
.dataItemsWrapper {
  height: 1.25rem;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0;
}

.downloadMenu,
.packageTrackingMenu {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &Item {
    align-items: center;
    display: flex;
    height: 2rem;
  }
}

.buttonRow {
  display: flex;
}

.buttonWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0;
}

.dataItems {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  gap: 0.25rem;
}
