@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.mainDiv {
  padding: calc(1rem + var(--obeta-safe-area-top-root)) 1rem
    calc(1rem + var(--obeta-safe-area-bottom-root)) 1rem;
  @include below-sm {
    height: 100%;
    overflow: auto;
  }

  @include sm {
    padding: 1.5rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 6rem);
  }
  @include lg {
    padding: 2.5rem;
    width: 45.75rem;
  }
  background-color: $white;
  overflow: hidden; //needed as the submit button is floated
}

.heading {
  display: flex;
  align-items: center;
  @include below-sm {
    height: 2rem;
  }
}

.noMargin {
  margin: 0;
}

.textInquireOffer {
  flex-grow: 1;
}

.textInfoForInquiry {
  line-height: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.formStyles {
  width: 100%;
  button {
    width: fit-content;
    margin-left: auto;
  }
}

.fieldWrapper {
  margin-top: 1rem;
}

.submitButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  @include sm {
    justify-content: flex-end;
  }
}
