@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.root {
  display: flex;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

@mixin size($val: 0) {
  width: $val;
  height: $val;
  min-width: $val;
  min-height: $val;
  max-width: $val;
  max-height: $val;
}

.size80 {
  @include size(5rem);
}

.size223 {
  @include size(13.93rem);
}

.size160 {
  @include size(10rem);
}

.size140 {
  @include size(8.75rem);
}

.size128 {
  @include size(8rem);
}

.size124 {
  @include size(7.75rem);
}

.size116 {
  @include size(7.25rem);
}

.size112 {
  @include size(7rem);
}

.size48 {
  @include size(3rem);
}
