@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @include below-sm {
    gap: 1.75rem;
  }
  & > * {
    /**
      Make sure there space between footer and last element
      We add padding because we don't to get "gap" effect which you will get
      in case background of container is different from element's background
    **/
    &:last-child {
      padding-bottom: $shopLastElementPadding;
    }
    &:last-child:empty {
      display: block;
      padding-bottom: 0;
    }
  }
}
.header {
  display: grid;
  grid-gap: 1.5rem;
  @include below-sm {
    grid-gap: 1rem;
  }
}

.row {
  position: relative;
  @include articleDetailLeftRightPadding;
  &.rightOverflowing {
    padding-right: 0;
  }
  &:empty {
    display: none;
  }
}
.titleSpacing {
  @include articleDetailLeftRightPadding;
  padding-left: 0 !important;
}

.coloredBackground:not(:empty)::before {
  content: '';
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  background-color: $lightGray5K;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
