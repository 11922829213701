/* ----------------------------------------------
 * Generated by Animista on 2020-9-12 12:48:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.flip {
  animation: flip-horizontal-bottom 1s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.blink {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes flip-horizontal-bottom {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(-180deg);
  }
}

@-webkit-keyframes flip-horizontal-bottom {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes flip-horizontal-bottom {
  0% {
    transform: rotateX(0);
  }

  50% {
    transform: rotateX(-180deg);
  }

  100% {
    transform: rotateX(-180deg);
  }
}
