@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/animations.scss';

.status-indication--loading,
.status-indication--not-found {
  width: 100%;
  padding: 2rem;
  text-align: center;
  color: var(--descriptive-text-color);

  .loader {
    @extend .flip;
    width: 3rem;
    height: 3rem;
    opacity: 0.5;
    background-color: var(--descriptive-text-color);
    border-radius: 4px;
    margin: auto;
  }
}
