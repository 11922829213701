@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.mainDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectionBarButtonsGroup {
  width: min-content;
  display: flex;
  gap: 1rem;
}

.totalSumSection {
  display: flex;
  gap: 0.25rem;
}
