@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.item {
  display: flex;
  align-items: center;
  @include columnGap(0.5rem);
  min-width: 0;
  color: $darkGray;

  .label {
    cursor: pointer;
  }
}

.component {
  padding: 0;
  color: $darkGray34K;
}

.checkbox,
.radio {
  @include below-lg {
    padding: 0.25rem;
  }
}

.checkbox {
  & > svg {
    width: 20px;
    height: 20px;

    @include below-lg {
      width: 24px;
      height: 24px;
    }
  }
}

.radio {
  & > span svg {
    width: 20px;
    height: 20px;

    @include below-lg {
      width: 24px;
      height: 24px;
    }
  }

  & > span svg path {
    transform: scale(1.2);
    transform-origin: center;
  }
}
.disabled {
  color: $darkGray34K;
  border-color: $darkGray34K;
  opacity: unset;
}
