@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.turntableimage {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    visibility: hidden;
  }
}

.turntableimage__framewrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: nowrap;
}

.turntableimage__frame {
  flex-shrink: 0;
  flex-grow: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}
