@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.root {
  padding: 1.5rem calc(2.5rem + var(--obeta-safe-area-right-root, 0px)) 1.5rem
    calc(2.5rem + var(--obeta-safe-area-left-root, 0px) + var(--obeta-safe-area-bottom-root, 0px));

  &.isIos {
    padding: 1.5rem calc(2.5rem + var(--obeta-safe-area-right-root, 0px)) 1.5rem 2.5rem;
  }

  border-radius: 0.25rem;
  background-color: $lightGray3K;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 50rem;
    margin: auto;
  }
}
