@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.categoryIconWrap {
  border-radius: 0.25rem;
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  &.xs {
    width: 1.25rem; //20px;
    height: 1.25rem;
    .categoryIcon {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  &.md {
    width: 2rem; //32px;
    height: 2rem;
    .categoryIcon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &.lg {
    width: 4rem; //64px;
    height: 4rem;
    .categoryIcon {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  .categoryIcon svg {
    width: 100%;
    height: 100%;
  }

  &.xs,
  &.md,
  &.lg {
    .categoryIcon {
      &.xsImg {
        width: 1.25rem;
        height: 1.25rem;
      }
      &.mdImg {
        width: 1.5rem;
        height: 1.5rem;
      }
      &.lgImg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
}
