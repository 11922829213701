@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.actions {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  width: fit-content;
}

.actionsItem {
  height: 2rem;
  padding: 0;
  width: 2rem;
  &:global(.MuiIconButton-root:active) {
    opacity: 1;
  }
  & svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  @include lg {
    height: 1.25rem;
    width: 1.25rem;
    & svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}

.card {
  display: flex;
  flex: 1;
  padding: 1rem;
}

.cartButton {
  margin-top: 1rem;
}

.content {
  flex: 1;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.flags {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  width: fit-content;
}

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  align-items: center;
  display: flex;
  height: 1.5rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  width: 100%;
}

.imageGrid {
  display: grid;
  gap: 0.25rem;
  height: 1.5rem;
  margin-top: 1rem;
  grid-template-columns: repeat(11, minmax(0, 1fr));
  width: 100%;
}

.imageGridItem {
  align-items: center;
  display: flex;
  & img {
    width: 1.5rem;
    max-height: 1.5rem;
    object-fit: contain;
  }
  & svg {
    color: $darkGray21K;
    height: 1.125rem;
    width: 1.125rem;
  }
}

.item {
  display: flex;
  margin-right: 2rem;
  @include sm {
    margin-bottom: 0rem; // Row Spacing handled by MUI Grid Element
  }
}

.item:nth-last-child(3n + 1) {
  margin-right: 0rem;
}

.name {
  display: -webkit-box;
  flex: 1;
  margin-bottom: 0.75rem;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
