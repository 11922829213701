@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.root {
  min-height: 100%;
  max-width: 100%;
  opacity: 1;
  display: grid;
  width: 66%;

  &.fullWidth {
    width: 100%;
  }

  @include md {
    width: 50%;
  }
}

.wrapper {
  height: 100%;
  width: 100%;
}

.paper {
  background-color: $lightGray8K;
  color: $darkGray;
  display: flex;
  justify-content: flex-end;
}
