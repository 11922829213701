@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.carts {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.shoppingCartList {
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: column;
  @include above-sm {
    padding-bottom: 2rem;
  }
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  :global .MuiToolbar-root {
    min-height: 28px;
  }
}

.toolbar {
  padding: 0 !important;
  flex-direction: row;
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  min-height: auto;
  margin-bottom: 1rem;
  gap: 1rem;
}
.title {
  flex-grow: 1;
}

.shoppingCartEmptyBoxWrapper {
  display: flex;
  justify-content: center;
  padding-top: 9rem;
  padding-bottom: 9rem;
  @include below-sm {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
}

.toolbar {
  display: flex;
}
.appBarWrapper {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @include below-sm {
    padding-bottom: 1rem;
  }
}
