@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.background {
  @include grayFullWidthBg;
}

.newsPageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
