@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.skeletons {
  display: flex;
  flex-direction: column;
  &Content {
    margin-bottom: 1.5rem;
  }
  &List {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &Subtitle {
    margin-bottom: 1.5rem;
  }
  &Title {
    margin-bottom: 0.5rem;
  }
}
