@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.container {
  padding-bottom: 1rem;
}

.leftborder {
  border-left: 0.375rem solid $darkGray55K;
  &.borderless {
    border-left: none;
  }
  .icon {
    fill: $darkGray55K;
    color: $darkGray55K;
  }
  &.primary {
    //primary
    border-color: $red100pct;
    .icon {
      fill: $red100pct;
      color: $red100pct;
    }
  }
  &.secondary {
    //secondary
    border-color: $darkGray;
    .icon {
      fill: $darkGray;
      color: $darkGray;
    }
  }
  &.success {
    //success
    border-color: $green;
    .icon {
      fill: $green;
      color: $green;
    }
  }
  &.warning {
    //warning
    border-color: $orange;
    .icon {
      fill: $orange;
      color: $orange;
    }
  }
  &.darkGray55K {
    border-color: $darkGray55K;
    .icon {
      fill: $darkGray55K;
      color: $darkGray55K;
    }
  }
  &.darkGray {
    border-color: $darkGray;
    .icon {
      fill: $darkGray;
      color: $darkGray;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.125rem 0.25rem;
  align-items: center;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.cardContent {
  display: grid;
  grid-template-columns: max-content 1fr auto auto;
  &.withoutActionEl {
    grid-template-columns: max-content 1fr auto;
  }
}

.card {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0;
  width: 100%;
  max-width: 78.125rem;
  margin: 0 auto;
}

.closeButton {
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 0;
  justify-content: flex-end;
  margin: 0 0 0 0.5rem;

  :global .MuiButton-startIcon {
    margin: 0;
  }
}

.flexCenter {
  display: flex;
  align-items: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.halfMarginRight {
  margin-right: 0.5rem;
}

.halfPadding {
  padding: 0.5rem;
}

.whiteInfoBackground {
  background-color: $white;
}

.lightInfoBackground {
  background-color: $lightGray5K;
}

.mediumInfoBackground {
  background-color: $lightGray8K;
}

.darkInfoBackground {
  background-color: $darkGray55K;
}

.alignSelfStart {
  align-self: start;
}

.simpleNotification {
  border-radius: 4px;
}

.multirowContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
