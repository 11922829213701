@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.containerBox {
  position: fixed;
  box-shadow: 0 0 15px 0 $black15pct;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.actionButtonsGrid {
  display: flex;
  gap: 1rem;
  > button {
    min-width: 4.313rem;
    @include sm {
      min-width: 4.5rem;
    }
  }
}

.deleteButton {
  min-width: 4.313rem;
  @include sm {
    min-width: 4.5rem;
  }
  @include lg {
    min-width: 7.688rem;
  }
  height: 2rem;
}

.submitButton {
  display: flex;
  min-width: 2rem;
  padding-left: 0;
  padding-right: 0;
  @include sm {
    gap: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  :global .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0;
  }
}

.cartMenuItem {
  min-width: 11.063rem;
}

.templateMenuItem {
  min-width: 10.813rem;
}

.menuPaper {
  max-height: 11rem;
}

.selectionBar {
  width: 100%;
  &Cart {
    justify-content: flex-end;
  }
  &Item {
    display: flex;
    align-items: center;
    width: fit-content;
  }
}
