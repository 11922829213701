@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

$rowHeight: 2.25rem;

.list {
  flex: 100;
  overflow: hidden;
}

.itemWrap {
  display: flex;
  justify-content: space-between;
  height: 2rem;
  align-items: center;
  margin-bottom: 0.25rem;
}

.toEtimAttrs {
  color: $darkGray55K;
}

.size {
  margin-left: 0.625rem;
}

:export {
  etimClassRowHeight: $rowHeight;
}
