@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root,
.categoriesWrap {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: minmax(0, 1fr);
}

.categoriesWrap {
  grid-gap: 1rem;
}
.categoriesText {
  margin-right: 0.5rem;
  white-space: pre-wrap;
}
