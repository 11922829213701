@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';

.cart-list-item-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid transparent;
}

.hovered-cart:hover {
  border: 1px solid $lightGray;
}

.active-cart {
  border: 1px solid $red100pct;
  @include dropShadow2;
}

.marked-cart {
  border-left: 0.25rem solid $green;
}

.additional-text {
  color: $green;
}

.cart-name {
  color: $darkGray;
}
