@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.clerkDataItem {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  &Icon {
    cursor: pointer;
    width: 1.25rem;
  }
  &StartLetter {
    color: $primaryRed;
  }
  &Value {
    color: $darkGray;
    @include sm {
      text-decoration: none;
    }
  }
}
