@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.teasers-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1 1 auto;
}

.teasers-section {
  padding: 0;
  box-sizing: border-box;

  display: grid;
  z-index: 1;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  .aspect {
    width: 100%;
    padding-top: 100%;
    height: 0;
    position: relative;
    background-color: $lightGray5K;
    > * {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  @include sm {
    gap: 2rem;
  }
  @include lg {
    padding: 2rem 0 2rem 2rem;
  }

  &.extended {
    padding: 0;
    gap: 1.5rem;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'a a b b c c'
      'd d d e e e';

    > *:nth-child(1) {
      grid-area: a;
    }
    > *:nth-child(2) {
      grid-area: b;
    }
    > *:nth-child(3) {
      grid-area: c;
    }
    > *:nth-child(4) {
      grid-area: d;
    }
    > *:nth-child(5) {
      grid-area: e;
    }
    @include below-sm {
      grid-template-areas:
        'a a a b b b'
        'c c c c c c'
        'd d d e e e';
    }
  }
}
