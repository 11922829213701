@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import './mixins.scss';

.layout {
  display: flex;
  flex-direction: column;
  @include columnGap(1rem);
  width: 100%;
}

.imageAndContentWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 0.625rem;
}

.productImage {
  margin-right: 1rem;
}

.valuesWrapper {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: end;
  width: 100%;
}
.stocksAndLabelWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
  justify-items: flex-start;
  column-gap: 1rem;
}
