@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.shipping {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  min-height: 1.5rem;
  padding: 0.25rem 0rem;
  width: 100%;
  &Detail {
    @include cxl {
      gap: 0.25rem;
      width: auto;
    }
  }
  &Title {
    width: 6rem;
    &Detail {
      @include cxl {
        font-size: 0.875rem;
        width: auto;
      }
    }
  }
  &Value {
    width: calc(100% - 6rem);
    &Detail {
      @include cxl {
        font-size: 0.875rem;
        width: auto;
      }
    }
  }
}
