@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.category-menu-item {
  @include ellipsis;
}

.category-menu-sub-item {
  @include ellipsis;
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.arrow-icon {
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  justify-self: flex-end;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include lg {
    gap: 0rem;
  }
}

.menu {
  padding: 1rem 0;
}

.paper {
  margin: 1rem 1rem 0 0;
}

.desktop {
  height: 1.25rem;
}

.menuItem {
  height: 2.25rem;
  min-height: 0;
  padding: 0;
  @include sm {
    height: 2rem;
    padding: 0 1rem;
  }
  @include lg {
    height: 2.25rem;
  }
}

.printMenuItem {
  display: flex;
  justify-content: space-between;
}

.printIconAndTextWrapper {
  display: flex;
  align-items: center;
}

.listItemIcon {
  min-width: 2.5rem;
  @include lg {
    height: 1.25rem;
    min-width: 2.25rem;
    width: 1.25rem;
    & > svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}

.MuiMenuItem-root {
  @include above-sm {
    padding-top: 4rem;
  }
}

.mobileHeader {
  display: flex;
  align-items: center;
  height: 2rem;
  justify-content: space-between;
}

.mobileRoot {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
}
