@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.controlWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.searchWrapper {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: center;
}

.supplierTypeFilterWrapper {
  margin-bottom: 1rem;
}

.select {
  width: 100%;
  @include sm {
    margin-left: 1rem;
    width: auto;
  }
  @include lg {
    margin-left: 2rem;
  }
}
