@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.catalogTitle {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.categoriesWrap {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  grid-template-columns: minmax(0, 1fr);
}

.page {
  align-self: flex-end;
}
