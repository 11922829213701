@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.container {
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem 0 0.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  &Delivery {
    color: $darkGray55K;
  }
}

.green {
  color: $green;
}
