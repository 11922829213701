@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.top-section-background {
  @include grayFullWidthBg;
}

.start-page-wrapper {
  background: $white;
}

.top-section-wrapper {
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: $lightGray5K;
  position: relative;

  @include below-sm {
    background: $white;
    padding: 1.5rem 0 0;
  }
  @include lg {
    padding: 0;
  }
}

.top-section-wrapper .teasers-section {
  justify-content: flex-end;
}
.news-card-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 1.875rem;
}
.page-wrapper {
  margin: 0 10rem;
}

.categories-list-wrapper {
  position: relative;
  z-index: 2;
  //This is for firefox only:
  scrollbar-color: $darkGray55K white;
  > div {
    background-color: $white;
  }
}
