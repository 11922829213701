@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins';
@import 'libs/assets/theme/coreVariablesV2.scss';

.wrapper {
  display: flex;
  column-gap: 3rem;
  row-gap: 1rem;

  @include above-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  @include below-sm {
    flex-direction: column;
  }
}
