@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.badgeWrapper {
  display: flex;
}

.badge {
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
  background-color: $lightGray8K;
  border-radius: 0.25rem;
  align-items: center;
}

.badgeCloseIcon {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

.multipleBadges {
  display: flex;
  gap: 1rem;
}
