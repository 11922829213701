@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

$infoSectionWrapperHeight: 2.25rem;

:export {
  infoSectionWrapperHeight: $infoSectionWrapperHeight;
}

.infoSectionWrapper {
  background-color: $lightGray8K;
  height: $infoSectionWrapperHeight;
  width: 100%;
  display: flex;
  justify-content: center;

  &.withInfoMessage {
    background-color: $white;
    border-bottom: 1px solid $lightGray;
  }
}

.infoSection {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: $shopMaxWidth;
  justify-content: space-between;
  align-items: center;
  @include topSectionPadding;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &.withInfoMessage {
    justify-content: center;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    .warning {
      color: $red100pct;
    }

    .error {
      color: $green;
    }
  }

  .text {
    margin-right: 8px;
  }

  .link {
    text-decoration: none;
  }

  .text,
  .link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
