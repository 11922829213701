@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.layout {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* three equal-width columns */
  gap: 1rem;

  :global .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
}

.option {
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid $darkGray34K;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1.125rem;
  &.selectedOption {
    border: 1px solid $primaryRed;
    box-shadow: 0 0 15px 0 $black15pct;
  }
}

.optionText {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.625rem;

  &.disabled {
    color: $darkGray34K;
  }
}

.option:not(.disabled):hover {
  box-shadow: 0 0 15px 0 $black15pct;
}

.optionIconContainer {
  width: 2.25rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: 1px solid $darkGray21K;
}

.optionIcon {
  display: flex;
  svg {
    height: 20px;
    width: 20px;
  }

  &.disabled {
    color: $lightGray;

    &.colored {
      opacity: 0.4;
    }
  }
}

.infoBlockDiscount {
  width: fit-content;
}

.creditCartContainer {
  display: flex;
  gap: 0.5rem;
}
