@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.header {
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  & > * {
    margin-right: 1.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.actionButtonBox {
  margin: auto;
}

.popover {
  padding: 1rem 1rem calc(1rem + var(--obeta-safe-area-bottom-root)) 1rem;
  @include rowGap(1.5rem);
}

.titleContainerClassName {
  display: flex;
  gap: 0.5rem;
}
