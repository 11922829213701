@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.heading {
  display: flex;
  justify-content: space-between;
  flex-wrap: unset;
  gap: 1rem;
}

.container {
  max-width: 50rem;
  padding: 1.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
}

.titleWide {
  white-space: nowrap;
}

.infoText {
  margin: 1rem 0 0;
}

.showShippingGroupTab {
  min-width: 0;
}

.titleUnderline :global .MuiTabs-indicator {
  bottom: 8px;
}
