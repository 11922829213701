@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.topSectionBackground {
  @include grayFullWidthBg;
  z-index: 0;
}

.sectionWrapper {
  position: relative;
}

.headline {
  font-size: 1.5rem;
  font-weight: 900;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  line-height: 1.75rem;
}

.conditionsContainer,
.condition {
  font-size: 1rem;
}

.bulletList {
  margin-left: 0.5rem;
}

.checkboxContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  &.center {
    justify-content: center;
  }
}

.submitButton {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
