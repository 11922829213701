@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.popover {
  width: 100%;
  padding: 0;
  max-width: unset;
  max-height: unset;
  border-radius: 0;
  top: unset !important; // reset default styles set via style attr
  bottom: 0 !important; // reset default styles set via style attr
  left: 0 !important; // reset default styles set via style attr
  box-shadow: none;
  background-color: transparent;
  @include dropShadow2;
}

.popoverPaper {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-areas:
    'Input Cancel Accept'
    'Options Options Options';
  row-gap: 1rem;
  column-gap: 0.75rem;
  border-radius: 0.25rem;
  @include dropShadow2;
}

.textField {
  grid-area: Input;
}
.inputElement {
  min-width: 1rem;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
}

.textFieldAdornment {
  margin-left: 0.5rem;
}

.inputElement,
.textFieldAdornment {
  height: 1.25rem;
  line-height: 1.25rem;
}

.cancel {
  grid-area: Cancel;
}

.accept {
  grid-area: Accept;
}

.option {
  padding: 0.5rem 1rem;
  color: $darkGray;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
}

.selected {
  font-weight: 700;
  background-color: $lightGray5K;
}

.paper {
  width: initial;
}
