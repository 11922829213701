@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/mixins.scss';

.basicStyling {
  background-color: $white;
  border-radius: 4px;
  padding: 1rem;
}

.fieldRow {
  display: flex;
  flex-direction: column;
  @include above-sm {
    flex-direction: row;
  }
  gap: 1rem;
}

.formFieldWrapper {
  width: 100%;
}

.resetPasswordWrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.errorWrapper {
  display: flex;
  gap: 0.25rem; //4px
}

.textInput {
  margin-top: 0.25rem;
  width: 100%;
}
