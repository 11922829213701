@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.infoText {
  margin: 0 0 0.5rem 2.25rem;
}

.success {
  color: $green;
}

.formControlLabel {
  margin-left: 0;
  // TODO Replace with 'our' instance of RadioItem once it has been refactored to be responsive
  :global .MuiRadio-root {
    width: 2.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    @include lg {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
  span {
    display: flex;
    align-items: center;
  }
}

.enlargedRadioIconForTouchDevices {
  :global .MuiSvgIcon-root {
    font-size: 1.5rem;
  }
}

.xpressTitle {
  margin-right: 0.25rem;
}

.cursorPointer {
  cursor: pointer;
}

.optionsOuterGrid {
  padding-top: 1rem;
  margin-left: 0;
}

.tooltip {
  padding: 1rem;
  max-width: 20rem;
}

.type {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include lg {
    gap: 1rem;
  }
}

.warning {
  color: $orange;
}

.withLink {
  a {
    color: inherit;
    text-decoration: underline;
  }
}
