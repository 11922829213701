@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.infoSection {
  .obetaIcon {
    width: 5rem;
    height: 3.0625rem;
    color: $red100pct;
    margin-bottom: 1rem;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .addCardButtonWrapper {
    width: fit-content;

    .buttonWrapper {
      max-width: fit-content;
    }

    .cardUploadError {
      color: $red100pct;
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      position: absolute;

      span {
        color: $red100pct;
      }

      .icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
