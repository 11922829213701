@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.titleLine {
  display: flex;
  justify-content: space-between;
}

.titleText {
  margin-bottom: 1rem;
}

.searchWrapper {
  margin-bottom: 1.5rem;
  @include lg {
    margin-bottom: 1rem;
  }
}
