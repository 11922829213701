@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.actionBar {
  align-items: center;
  background: $white;
  bottom: 0;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
  left: 0;
  padding: 0.75rem 1.5rem calc(0.75rem + var(--obeta-safe-area-bottom-root)) 1.5rem;
  position: fixed;
  right: 0;
}
