@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.list {
  display: flex;
  flex-direction: column;
}

.noResults {
  @include lg {
    margin-top: 0.5rem;
  }
}

.unknownOrderItemDiv {
  padding-bottom: 0.5rem;
  width: 100%;
}

.orderItemDiv {
  padding-bottom: 0.5rem;
}
