@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.wrapper {
  padding: calc(var(--obeta-safe-area-top-root) + 1rem) 1.5rem 1rem 1.5rem;
  width: 100%;
  height: 100%;

  .topSection {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    flex-direction: row;

    .topSectionTitle {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
    }
  }

  .contentInfo {
    .category {
      .categoryTitle {
        margin-bottom: 1rem;
        font-size: 1rem;
      }

      .categoryItems {
        .categoryItem {
          display: flex;
          justify-content: space-between;
          padding: 6px 0;

          .categoryItemDescription {
            color: #929292;
            font-size: 14px;
          }

          .categoryItemValue {
            font-size: 14px;
          }
        }
      }
    }
  }
}
