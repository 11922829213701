@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import '../../../../../assets/theme/mixins';
@import '../../../../../assets/theme/coreVariablesV2';

$itemHeight: 1.5rem;

.filtersMobileRoot {
  display: inline-block;
}

.list {
  background-color: $white;
  @include rowGap(0.5rem);
}

.staticMessage {
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  background-color: $white;
  border-radius: 0.25rem;

  .icon {
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
  }
}

.search {
  margin-bottom: 1rem;
}

:export {
  itemHeight: $itemHeight;
}

.size {
  margin-left: 0.25rem;
}
