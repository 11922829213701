@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.outerWrap {
  height: 2rem;
  box-sizing: border-box;
  &.small {
    /**
    currently only primary and tertiary alter their outer heights,
    for the rest of the button it only has implications for the size of the icons
    **/
    &.primary {
      height: 1.5rem;
    }
    &.tertiary {
      height: 1.25rem;
    }
  }
  &:hover {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  }
  &.tertiary {
    &:hover {
      box-shadow: none;
    }
  }
  display: inline-flex;
  &.fullWidth {
    display: flex;
  }
  &.disabled {
    box-shadow: none;
    .button-wrapper,
    .splitpart {
      opacity: 1;
      background-color: $darkGray34K;
      * {
        color: $white;
      }
      &.secondary {
        background-color: $white;
        border-color: $darkGray34K;
        * {
          color: $darkGray34K;
        }
      }
      &.light {
        background-color: $white;
        border-color: $lightGray;
        * {
          color: $darkGray34K;
        }
      }
      &.tertiary {
        background-color: transparent;
        * {
          color: $darkGray34K;
        }
      }
    }
  }
}
.button-wrapper,
.splitpart {
  display: flex;
  flex: 1;
  overflow: hidden;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  * {
    color: $white;
  }
  span {
    @include ellipsis;
  }
  &:active {
    opacity: 1;
  }
  &.primary {
    background-color: $primaryColor;
    &:active {
      background-color: $red89pct;
    }
  }
  &.secondary {
    background-color: $white;
    border: 1px solid $secondaryColor;
    * {
      color: $secondaryColor;
    }
    &:active {
      border-color: $darkGray55K;
      * {
        color: $darkGray55K;
      }
    }
  }
  &.tertiary {
    background-color: transparent;
    * {
      color: $darkGray;
      // TODO We either cannot set this by default,
      //  or must allow custom value for items such as this one: https://www.figma.com/file/ecHZSQzDC4bIep2dpB8atn/Obeta-mit-Styleguide?node-id=10284-302697&t=q5rbhJ06vr2z7mWD-4
    }
    &:hover {
      box-shadow: none;
    }
    &:active * {
      color: $darkGray55K;
    }
    padding: 0;
  }
  &.dark {
    background-color: $darkGray;
    &:active {
      background-color: $darkGray55K;
    }
  }
  &.light {
    border: 1px solid $lightGray;
    background-color: $white;
    * {
      color: $secondaryColor;
    }
    &:hover {
      border-color: $secondaryColor;
    }
    &:active {
      border-color: $lightGray;
    }
  }
  &:hover {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.iconRight {
  margin-left: 0.5rem;
}
.splitButton {
  .button-wrapper {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .splitpart {
    padding: 0.375rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex: auto 0 0;
  }
}

.icon {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.iconPrimary {
  svg > path {
    fill: $red100pct;
  }
}
.iconSecondary {
  svg > path {
    fill: $darkGray;
  }
}
.iconSuccess {
  svg > path {
    fill: $green;
  }
}

.tertiary.large .icon {
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  svg {
    width: 24px;
    height: 24px;
  }
}

.small {
  &:not(.tertiary) {
    .button-wrapper {
      padding: 0.25rem 0.625rem;
    }
  }
  .splitpart {
    padding: 0.25rem;
  }
  &:not(.tertiary, .secondary) {
    .icon {
      width: 16px;
      height: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.iconOnly {
  width: 2rem;
  &:not(.tertiary) .button-wrapper {
    padding: 0.375rem;
  }
  &.secondary .button-wrapper {
    padding: 0.25rem;
  }
  &.tertiary {
    &.small {
      width: 1.25rem;
    }
    .button-wrapper {
      padding: 0;
    }
  }
  &.large {
    &.secondary {
      .icon {
        width: 24px;
        height: 24px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
