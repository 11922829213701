@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.headerText {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.bodyText {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.saveButtonWrapper {
  width: 100%;
  padding: 0 4rem;

  @include below-md {
    padding: 0 1.5rem;
  }
}

.saveButton,
.submitButton {
  width: 37.625rem;
  margin-top: 2rem;

  @include below-md {
    width: 100%;
    margin-top: 2rem;
  }
}

.submitButton {
  margin-bottom: 4rem;
}

$loader-size: 8rem;
$check-height: calc(#{$loader-size}/ 2);
$check-width: calc(#{$check-height}/ 2);
$check-left: calc(#{$loader-size}/ 6 + #{$loader-size}/ 12);

.circleLoader {
  border: 0.625rem solid $darkGray;
  position: relative;
}

.circleLoader,
.circleLoader:after {
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.checkMark {
  opacity: 1;
  height: $check-height;
  width: $check-width;
  transform-origin: left top;
  border-right: 0.625rem solid $darkGray;
  border-top: 0.625rem solid $darkGray;
  content: '';
  left: 1.4rem;
  top: 3.7rem;
  position: absolute;
  animation-duration: 1.2s;
  animation-timing-function: ease;
  animation-name: checkMark;
  transform: scaleX(-1) rotate(135deg);
}

@keyframes checkMark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
