@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.checkbox {
  padding: 0;
}

.bottomTablet {
  padding-top: 0.5rem;
  gap: 1.25rem;
}

.voltimumIcon {
  height: 1.3rem;
}

.subActions {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  order: 200;
  gap: 1rem;

  &.compact {
    margin-top: 0.25rem;
  }
}

.replacementArticle {
  margin-left: auto;
}

.transferIdsButton {
  margin-left: auto;
}
