@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.content {
  display: grid;
  max-width: $shopMaxWidth;
  margin: 0 auto;
  position: relative;
  color: $darkGray;
  z-index: 10;

  @include below-sm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'pageTitle  filtersMobile'
      'search     search'
      'feed       feed';
    row-gap: 1.5rem;
  }

  @include sm {
    grid-template-columns: max-content 1fr;
    grid-template-rows: repeat(3, max-content) 1fr;
    grid-template-areas:
      'pageTitle      pageTitle'
      'search         search'
      'filtersMobile  activeFilters'
      'feed           feed';
    row-gap: 1rem;
  }

  @include md {
    grid-template-columns: max-content 1fr;
    grid-template-rows: repeat(3, max-content) 1fr;
    grid-template-areas:
      'pageTitle  pageTitle'
      'aside      search'
      'aside      activeFilters'
      'aside      feed';
    gap: 1rem 1.5rem;
  }
}

.activeFilters {
  grid-area: activeFilters;
  @include sm {
    margin-left: 1.375rem;
  }
  @include md {
    margin-left: unset;
  }
}

.pageTitle {
  grid-area: pageTitle;
  align-self: center;
}

.filtersMobile {
  grid-area: filtersMobile;
}

.search {
  grid-area: search;
}

.feed {
  grid-area: feed;
}

.aside {
  grid-area: aside;
}
