@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import './mixins.scss';

.layoutWrapper {
  display: flex;
  @include cardContent;
}

.layout {
  display: flex;
  @include columnGap(1rem);
  width: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  @include rowGap(0.5rem);
  &DeadProduct {
    justify-content: space-between;
    padding: 0.25rem;
  }
}

.printContent {
  @include rowGap(0.25rem);
}

.headerWrap,
.footer {
  display: grid;
  grid-template-columns: auto 1fr;
  &.singleColumn {
    grid-template-columns: 1fr;
  }
  &.shippingInfo {
    grid-template-columns: auto 1fr auto;
    justify-items: end;
  }
  justify-content: space-between;
  grid-gap: 1rem;
  height: fit-content;
  direction: revert;
  > .header {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}
.footer {
  flex: 1;
  align-items: flex-end;
}

.headerWrap {
  min-height: 1.25rem;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  width: 100%;
  grid-gap: 0.5rem;
  align-self: flex-end;
  max-width: 100%;
  margin-top: 0.5rem;
}

.horizontalBottomElement {
  max-width: fit-content;
  justify-self: end;
  @include sm {
    padding-left: 1rem;
  }
}

.horizontalProperties {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  width: fit-content;
  &Center {
    align-items: center;
  }
  &DeadProduct {
    width: 100%;
  }
}

.box {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  height: fit-content;
  width: fit-content;
}

.positionedRelative {
  position: relative;
}

.wrapperCounterAndProductId {
  display: flex;
  justify-content: space-between;

  @include sm {
    margin-top: auto;
  }
}

.wrapperContentSlots {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.shippingValue {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 0;
}

.stocksAndLabelWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
  justify-items: flex-start;
  column-gap: 1rem;
}
