@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.ownerInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &Content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  @include sm {
    margin-top: 0.5rem;
    padding: 0 1rem;
  }
}

.item {
  display: flex;
  &Title {
    width: 4.5rem;
  }
}
