@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.dialog {
  padding: 1rem;
  min-width: 60%;
}

.title {
  display: flex;
  justify-content: space-between;
}

.textField {
  width: 100%;
}

.inputTitleContainer {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.dialogActions {
  padding: 0 1.5rem 1.5rem;
}

.badges {
  display: flex;
  gap: 0.5rem;
  padding: 1.5rem 0 0;
  flex-wrap: wrap;
  flex-grow: 0;
}

.badge {
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
  background-color: $lightGray8K;
  border-radius: 0.25rem;
  align-items: center;
}

.badgeCloseIcon {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

.badgesContainer {
  display: flex;
}
