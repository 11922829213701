@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.showLessIcon {
  transform: rotate(180deg);
}

.btnWrapper {
  display: flex;
  button div {
    justify-content: center;
  }
}

.start {
  justify-content: start;
}

.center {
  justify-content: center;
}
