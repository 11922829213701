@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
$borderWidth: 0.375rem;

.root {
  position: relative;
  box-shadow: 0px 0px 0.94rem rgba(0, 0, 0, 0.15);
  padding: 1rem;
  border-radius: 0.25rem;
  padding-left: #{$borderWidth + 1rem};
  display: flex;
}

.body {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: 0.5rem;
  flex: 1;
}

.message {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.border {
  position: absolute;
  height: 100%;
  width: $borderWidth;
  z-index: 10;
  top: 0;
  left: 0;
}

.grey {
  background: #929292;
}
