@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.titleWithTooltip {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.tooltipIcon {
  height: 1rem;
  width: 1rem;
  * {
    color: $darkGray !important;
  }
}

.allNotifyFields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.userToNotifyContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.userToNotifyBox {
  border: 1px solid $darkGray34K;
  border-radius: 4px;
  padding: 0.25rem 0.25rem 0.25rem 0.7rem;
  flex-grow: 1;
}

.deleteIconContainer {
  svg {
    height: 20px;
    width: 20px;
  }
}

.notificationInput {
  &.autoComplete {
    :global .MuiAutocomplete-input {
      padding: 0;
      margin-top: -0.125rem;
    }
    :global .MuiAutocomplete-root {
      padding: 0;
    }
  }
}

.titleWithoutCheckbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
