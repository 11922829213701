@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.scrollbar {
  height: 100%;
  max-height: inherit;
  overflow: auto;

  @include scrollbar;
}

.xsIdent {
  border-right: 1rem solid transparent;
  padding-right: 1rem;

  &.notScrollable {
    &.xsSize {
      padding-right: 1.375rem;
    }

    &.mdSize {
      padding-right: 1.5rem;
    }
  }
}

.xsSize {
  &::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
  }
}

.mdSize {
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
}
