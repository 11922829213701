@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 45rem;
  box-shadow: unset;
  border-radius: 0.25rem;
  padding: 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thanks {
  color: #28b05f;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.76rem;
  margin-top: 0.5rem;
}

.message {
  text-align: center;
  margin: 0.5rem 1rem;
  font-family: 'Lato', serif;
}

.close {
  text-transform: unset;
  padding: 0.5rem;
  width: 100%;
  max-width: 14.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #3f3f3e;
  border: 0.0625rem solid #3f3f3e;
  border-radius: 0.25rem;
}
