@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.pdf-layout-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
  padding: 2rem 0;
  /* Height is derived from oxomi catalog height + title */
  height: 233px;

  :global {
    .oxomi-catalog {
      margin-right: 0 !important;
      /* Duplicate the dimensions from oxomi in order to make them stable over possible oxomi updates */
      width: 150px;
      height: 206px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .oxomi-catalog-title {
      font-family: 'Lato', serif;
    }
  }
}
