@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.BoxContainer {
  display: flex;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 1rem;
}
.Avatar {
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  width: 2.5rem;
  height: 2.5rem;
}
.SkeletonBox,
.SkeletonInner {
  width: 100%;
  height: 2rem;
}
