@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.root {
  display: grid;
  grid-template-rows: 1fr auto auto;
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}

$maxModalWidth: 68.75rem;

.root,
.modal {
  @media (min-width: #{$maxModalWidth + 2*$modalMarginThreshold}) {
    max-width: $maxModalWidth;
  }

  @include landscape-below-sm {
    max-width: 100%;
    max-height: 100%;
  }
}

.root {
  @include landscape-below-sm {
    padding: calc(1.5rem + var(--obeta-safe-area-top-root)) 4rem var(--obeta-safe-area-bottom-root);

    .closeBtn {
      top: calc(1rem + var(--obeta-safe-area-top-root));
      right: 4rem;
    }

    .navigationLeft {
      margin-left: 0.5rem;
    }

    .navigationRight {
      margin-right: 0.5rem;
    }
  }
}

.rootFullScreen {
  border-radius: 0;
}

.zoomPan {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.selected {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.contentHold {
  margin: 0 auto;
}

.divider {
  border-color: $lightGray;
}

.thumbsWrapper {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2.5rem;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  box-sizing: border-box;
}

.withThumbsAdornment {
  margin: 0;
  margin-right: 2.5rem;
}
