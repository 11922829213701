@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.close {
  right: 1.5rem;
  top: calc(1.5rem + var(--obeta-safe-area-top-root));
}

.root {
  background-color: $white;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.stackScreen {
  padding-top: calc(1.5rem + var(--obeta-safe-area-top-root));
  padding-left: 1.5rem;
  padding-bottom: calc(1.5rem + var(--obeta-safe-area-bottom-root));
  padding-right: 1.5rem;
  box-sizing: border-box;
}
