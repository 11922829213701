@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.feedback {
  background-color: $darkGray;
  border-radius: 0.25rem;
  bottom: 2rem;
  display: none;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  position: fixed;
  right: 2rem;
  :global .MuiTypography-root {
    color: $white;
  }
  z-index: 1000;
  &Center {
    align-items: center;
  }
  @include md {
    display: flex;
  }
}

.feedbackButtons {
  align-items: center;
  display: flex;
  gap: 2.5rem;
}

.feedbackChangeConsent {
  &Info {
    &Icon {
      color: $white;
      height: 1.25rem;
      width: 1.25rem;
    }
  }
  &Settings {
    justify-content: flex-end;
  }
}

.feedbackContent {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  &Close {
    height: 1.25rem;
    width: 1.25rem;
    & path {
      fill: $white;
    }
  }
}

.feedbackModal {
  background-color: $white;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 1rem;
  padding: 2.5rem;
  width: 45.75rem;
  &Actions {
    align-items: center;
    display: flex;
    gap: 1.5rem;
    justify-content: flex-end;
  }
  &Content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &Form {
      display: flex;
      flex-direction: column;
      width: 100%;
      &CommentLength {
        text-align: right;
      }
      &TextField {
        width: 100%;
        :global .MuiOutlinedInput-root {
          height: 3.25rem;
        }
      }
    }
    &Success {
      display: flex;
      gap: 1rem;
      &Phone {
        display: flex;
        gap: 0.25rem;
        &T {
          color: $primaryRed;
        }
      }
    }
  }
  &Header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
