@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.root {
  bottom: 70px;
  padding: 1rem;
}

.messageWrapper {
  @include above-sm {
    min-width: 400px;
  }

  @include above-md {
    min-width: 600px;
  }

  display: flex;
  flex-direction: column;
  gap: 2px;

  .message,
  .header {
    color: $white;
  }
}
