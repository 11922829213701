@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.page {
  font-family: 'Roboto', sans-serif;
}

.actions {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.6875rem;
}

.content {
  padding: 1.5rem;
}
