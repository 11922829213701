@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.dialogWrapper {
  :global .MuiPaper-root {
    width: 51rem;
  }

  &.dialogTabletWide {
    :global .MuiPaper-root {
      width: 56.25rem;
    }
  }
  &.dialogTabletSmall {
    :global .MuiPaper-root {
      width: 45rem;
    }
  }
}

.dialog {
  padding: 1.5rem;
}

.addToCart {
  width: 13.625rem;
}

.mobileCloseIcon {
  display: flex;
}

.mobileBackIcon {
  height: 1rem;
}

.mobileBackButton {
  cursor: pointer;
}

.mobileMenu {
  position: fixed;
  box-sizing: border-box;
  width: calc(100vw - (100vw - 100%) - 2.425rem);
  //width: calc(100vw - 4rem);
  background: white;
  overflow: auto;
  top: 0;
  left: 0;
  padding-left: 1.5rem;
  z-index: 1;
}

.wrapperDeletePurgeButton {
  :global .MuiTypography-root {
    color: $red100pct;
    font-weight: 700;
  }
}

.paddingLeftRightOneHalfRem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
