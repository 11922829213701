@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.appBarWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.ordersWrapper {
  width: 100%;
}
.buttonDiv {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.background {
  @include grayFullWidthBg;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mobileRoot {
  position: relative;
  width: 100%;
  background-color: $white;
  overflow: auto;
  padding: 1.5rem;
}

.empty {
  display: flex;
  justify-content: center;
  // Position block centered
  padding-top: 9rem;
  padding-bottom: 9rem;
  @include below-sm {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
  width: 100%;
  height: fit-content;
}

.ordersList {
  color: $darkGray;
  display: flex;
  position: relative;
  z-index: 10;
  :global(.ReactVirtualized__Grid) {
    overflow: visible !important; // https://github.com/bvaughn/react-virtualized/issues/876#issuecomment-367029529
  }
  :global(.ReactVirtualized__Grid__innerScrollContainer) {
    overflow: visible !important;
  }
  &V1_1 {
    flex-direction: column;
    @include md {
      flex-direction: row;
      gap: 1.5rem;
    }
  }
}

.searchAndToolbar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @include sm {
    flex-direction: row;
  }
  &FlexDirectionColumn {
    flex-direction: column;
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  @include md {
    display: none;
  }
}

.newIconAndButtonWrapper {
  display: flex;
  align-items: center;
}

.menuItem {
  height: 2.25rem;
  min-height: 0;
  padding: 0;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
}
