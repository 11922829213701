@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.cell {
  display: flex;
  flex: 1;
}

.left {
  justify-content: left;
}

.center {
  justify-content: center;
}

.right {
  justify-content: right;
}
