@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  row-gap: 1rem;
  @include lg {
    row-gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.backAndShareButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include below-lg {
    align-items: center;
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  @include below-sm {
    flex-direction: column;
    row-gap: 0.5rem;
  }
  justify-content: space-between;
  width: 100%;
}

.visibilityStatus {
  display: flex;
  align-items: center;
}
