@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.orders-section-background {
  @include grayFullWidthBg;
  z-index: 0;
}

.orders-section-wrapper {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: $lightGray5K;
  position: relative;
}

.ordersContainer {
  width: 100%;
  // Required since the neighbouring element uses @mixin grayFullWidthBg
  position: relative;
  z-index: 2;
}

.cardWrapper {
  width: 21.875rem;
  height: 24.25rem;
  padding: 5px;
  box-sizing: border-box;
  @include below-sm {
    width: 100%;
    height: 26rem;
  }
}

.centered {
  justify-items: center;
}

.itemWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;

  :global .MuiLink-root {
    flex: 1 1 0;
  }
}
