@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem 1rem;
  color: $darkGray;
}

.remove {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.valuesContainer {
  @include listMaxHeight(2rem);
}
