@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.root {
  .crumb {
    width: auto;
    align-self: center;
  }
}

.filterTab {
  cursor: pointer;
  margin-top: 8px;
  padding-bottom: 8px;

  &.active {
    border-bottom: 2px solid #e2061b;
  }

  .filterTabLabel {
    &.active {
      color: #e2061b;
    }
  }
}
