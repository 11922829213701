@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
.root {
  .crumb {
    width: auto;
    align-self: center;
  }

  .chevronRight {
    color: $darkGray55K;
    min-width: 1.25rem;
    min-height: 1.25rem;
  }
}
