@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.root {
  display: flex;
  flex-direction: column;
}

.thumbWrap {
  box-sizing: border-box;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 0.0625rem solid transparent;
}

.thumb {
  background: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin setThumbSize($val: 0) {
  & .thumbWrap {
    width: $val;
    height: $val;
  }

  & .thumb {
    width: $val - 1;
    height: $val - 1;
  }
}

.selectedThumb {
  border: 0.0625rem solid $red100pct;
}

.size135 {
  @include setThumbSize(8.4375rem);
}

.size80 {
  @include setThumbSize(5rem);
}

.size64 {
  @include setThumbSize(4rem);
}
