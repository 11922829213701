@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
.root {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  position: relative;
  @include articleDetailLeftRightPadding;
}

.shortenedDescription,
.descriptionVirtual {
  max-height: 15rem;
  overflow: hidden;
}

.descriptionVirtual {
  z-index: -1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
}
