@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.globalNotificationWrapper,
.selectionBarNotificationsWrapper {
  margin: 0 auto;
  position: fixed;
  width: 100%;

  // In landscape mode, the safe-area boundaries should not cover the close icon.
  padding-right: calc(var(--obeta-safe-area-right-root, 0rem));
  padding-left: calc(var(--obeta-safe-area-left-root, 0rem));
}

.globalNotificationWrapper {
  z-index: 9999;
}

.selectionBarNotificationsWrapper {
  bottom: 4.5rem; /* 1 rem above selection bar */
  z-index: 9999;
}
