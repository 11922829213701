@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.shoppingcartitem {
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 1rem;
}

.headerGrid {
  width: inherit;
}

.imageGrid {
  padding-right: 0.917rem;
}

.dividerGrid {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.priceGrid {
  margin-bottom: 0.5rem;
}

.approvalGrid {
  color: $green;
  display: flex;
  margin-bottom: 1.75rem;
}

.shoppingcartitem:hover {
  box-shadow: 0 0 0.938rem 0 $black15pct !important;
}

.shoppingcartitemcontent {
  padding: 0 !important;
}

.shoppingcarticon {
  margin-right: 0.5rem;
}

.shoppingcartellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shoppingcartcount {
  margin-left: 0.5rem;
  vertical-align: middle;
  flex-grow: 1;
}

.enabled {
  border-left: 0.188rem solid $green;
}

.previewImage {
  width: 1.5rem !important;
  max-height: 1.5rem !important;
  object-fit: contain !important;
}

.fitWidth {
  width: fit-content;
}

.imageList {
  margin-bottom: 0;
  margin-top: 0;
}

.emptyCartGrid {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
