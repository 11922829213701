@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.promotion-page-content-wrapper {
  position: relative;
}

.promotion-page-content-background {
  @include grayFullWidthBg;
}

.promotion-page-content {
  position: relative;
  padding: 4rem 0;
  width: 100%;
  z-index: 10;
}

.promotion-page-content-title {
  margin-bottom: 1.5rem;
  color: $darkGray;
}

.promotion-page-product {
  margin-bottom: 0.5rem;
}
