@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.wrapper {
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--width, 50%);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: 100%;
}

.paper {
  min-height: 100%;
  max-width: 100%;
  border-radius: 0;
  box-shadow: none;
}
.slideUpAppearance {
  height: min-content;
  width: 100%;
  top: unset;
  bottom: 0;
}
