@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.card {
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  border-radius: 0;
  box-shadow: unset;
}

.dateWrapper {
  display: flex;
  justify-content: flex-end;
}

.date {
  color: #929292;
  font-size: 0.75rem;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 1.28rem;
}

.title {
  color: #3f3f3e;
  font-size: 0.8125rem;
  font-weight: 500;
}

.footer {
  display: flex;
  align-items: center;
  margin-top: 0.875rem;
}

.status {
  display: inline-block;
  border-radius: 0.875rem;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.2731rem;

  &--success {
    background-color: #27ae60;
  }

  &--error {
    background-color: #f07682;
  }
}
