@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.card {
  height: 100%; // allows card to match height of neighbouring card
  width: 100%;
  background-color: $lightGray5K;
  :global .MuiCardContent-root:last-child {
    padding-bottom: 1rem;
  }
}

.cardContent {
  // touch devices
  @include below-lg {
    padding: 1rem 0.75rem 1.5rem;
  }
  padding: 1rem;
}

.headerBox {
  display: flex;
  justify-content: space-between;
}

.contentBox,
.renzBox {
  padding-left: 1.75rem;
  display: flex;
  flex-direction: column;
}

.halfPaddingTop,
.contentBox {
  padding-top: 0.5rem;
}

.formControlLabel {
  margin: 0;
  :global .MuiRadio-root {
    padding-right: 0.5rem;
  }
}

.halfLineHeight {
  :global .MuiTypography-root {
    line-height: 0.5rem;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn,
.days,
.times {
  display: flex;
  flex-direction: column;
}

.times {
  margin-left: 0.25rem;
}
