@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.mainContainer {
  background-color: $white;
}

.fixedWidthOnA4 {
  width: 43.625rem; //698px;
}

.cartTitle {
  color: $darkGray;
  font-size: 1rem;
}

.headerLogoAndDate {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.marginTopOneAndHalfRem {
  margin-top: 1.5rem;
}

.marginTopOneRem {
  margin-top: 1rem;
}

.marginTopHalfRem {
  margin-top: 0.5rem;
}

.marginBottomHalfRem {
  margin-bottom: 0.5rem;
}

.marginBottomOneRem {
  margin-bottom: 1rem;
}

.marginBottom4px {
  margin-bottom: 0.25rem;
}

.margiTopHalfRem {
  margin-top: 0.5rem;
}

.boxOffer {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  flex-basis: 25%;
}

.textsOnTopOfEachOther {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.allInfoBoxes {
  display: flex;
  justify-content: space-between;
  color: $darkGray;
}

.boxShoppingCartNotOrderedYet {
  text-align: end;
  flex-basis: 50%;
}

.boxCustomerNumber {
  flex-basis: 25%;
}

.fontSizeOneRem {
  font-size: 1rem !important;
}

.fontSize14px {
  font-size: 0.875rem;
}

.fontSize12px {
  font-size: 0.75rem;
}

.fontSize10px {
  font-size: 0.625rem;
}

.fontSize8px {
  font-size: 0.5rem;
}

.shoppingCartSummaryValue {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-family: Lato, sans-serif;
}

.divB2B {
  margin-top: 1rem;
  //The value for margin-bottom has been selected with respect to the corresponding visual element in Figma
  //TODO: set margin-bottom
  margin-bottom: 0.375rem; //6px;
}

.textColorRunningHeader {
  color: $darkGray55K;
}

.textColorHeader {
  color: $darkGray;
}

.divCartSummary {
  //For now, setting the width to 100%. It should be tested when there is user info available.
  width: 100%;
  background-color: $lightGray;
  box-shadow: none;
}

.divSummaryAndInfo {
  display: flex;
  margin-bottom: 0.5rem;
}

.divider {
  border-color: $darkGray55K;
}

.divInfo {
  margin-left: 1.25rem; //20px;
  max-width: 48%;
  padding-top: 1rem;
}

.marginRight17px {
  margin-right: 1.063rem;
}

.simpleInfoBlockContainer {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.divFooter {
  background-color: $white;
  color: $darkGray55K;
  padding-bottom: 1rem;
  padding-top: 1.5rem;
}

.infoText {
  line-height: 1.125rem;
  font-size: 0.75rem;
}

.lineHeight20px {
  line-height: 1.25rem;
}

.noBoxShadow {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.obetaLogoColor {
  color: #ed1c29;
}

.infoNotificationNoPrice {
  height: min-content;
  align-items: unset;
}

.red100pctText {
  color: $red100pct;
}

.leftAligned {
  text-align: left;
}

.RightTextAlign {
  text-align: right;
}

.primaryRed {
  color: $primaryRed;
}

.productWrapper {
  break-inside: avoid;
}
.summaryBoxWrap {
  display: flex;
  flex-direction: column;
}
