@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.item-wrapper {
  display: flex;
  align-items: center;
  max-width: 15.063rem;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.item-image {
  margin-right: 0.5rem;
  height: 44px;
  width: 44px;
  max-width: 44px;
}

.product-image {
  height: 44px;
  width: 44px;
  max-width: 44px;
  object-fit: contain;

  &-broken {
    img {
      display: none;
    }
    &::before {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiIC8+PHBhdGggc3R5bGU9ImZpbGw6dmFyKC0tYmFja2dyb3VuZCwjZDBkMGQwKTsiIGQ9Ik0xOSAzSDVjLTEuMSAwLTIgLjktMiAydjE0YzAgMS4xLjkgMiAyIDJoMTRjMS4xIDAgMi0uOSAyLTJWNWMwLTEuMS0uOS0yLTItMnptMCAxNkg1di00LjU4bC45OS45OSA0LTQgNCA0IDQtMy45OUwxOSAxMi40M1YxOXptMC05LjQxbC0xLjAxLTEuMDEtNCA0LjAxLTQtNC00IDQtLjk5LTFWNWgxNHY0LjU5eiIvPjwvc3ZnPg==);
      display: flex;
      font-size: 1rem;
      color: #d0d0d0;
      z-index: 3;
      align-items: center;
      position: relative;
      justify-content: center;
      overflow: hidden;
      height: 44px;
      width: 44px;
    }
  }
}

.item-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 10.813rem;
}

.item-title {
  width: 100%;
  @include ellipsis;
  color: $darkGray;
}

.item-article-id {
  color: $darkGray;
}
