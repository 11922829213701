@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.headCheckoutDesktopVariant3 {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding: 0.18rem 0 0;
  & :global .MuiDivider-root {
    align-self: stretch;
  }
}

.gap {
  gap: 0.5rem;
}

.noResults {
  @include lg {
    margin-top: 1rem;
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.order {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1.5rem;
  &Info {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    @include lg {
      align-items: center;
    }
  }
}

.options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &ContentNext {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include sm {
      align-items: center;
      flex-direction: row;
    }
    &CheckboxAndSelect {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
    }
  }
  &Title {
    margin-top: 0.5rem;
  }
  @include sm {
    gap: 1rem;
  }
}
