@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root,
.list {
  display: flex;
  flex-direction: column;
}

.root {
  @include rowGap(1.5rem);
}

.list {
  @include rowGap(0.5rem);
}
