@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.buttons {
  display: flex;
  justify-content: center;
  gap: 0.625rem;
  & > * {
    flex-grow: 1;
    width: 100%;
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  max-width: 45.625rem;
  @include sm {
    padding: 2rem 4rem;
  }
  @include lg {
    width: 37.625rem;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
