@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn,
.days,
.times {
  display: flex;
  flex-direction: column;
}

.times {
  margin-left: 0.25rem;
}
