@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 0.5rem;
  overflow: initial;
}

.filters {
  display: flex;
  @include columnGap(0.5rem);
  overflow: hidden;
}

.chip {
  min-width: 0;
}
