@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins';

.card {
  & .cardContent:last-child {
    padding-bottom: 1rem;
  }
  position: relative;
}

.checkbox {
  padding: 0;
  font-size: 20rem;
  margin-left: -0.125rem;
}

.icon {
  height: 2rem;
  width: 2rem;
  @include lg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.completeDeliveryBox {
  margin-right: 0.25rem;
  margin-left: 0.375rem;
}

.completeDeliveryDisabled {
  color: $darkGray34K;
}

.dropdown {
  width: 100%;
  height: 2rem;
}

#offer-dropdown-heading {
  display: block;
  margin-bottom: 0.313rem;
}

#full-delivery-text {
  margin-left: 0.563rem;
  margin-right: 0.563rem;
}

.iconBeforeText {
  vertical-align: middle;
  margin-right: 0.438rem;
}

.blackLink {
  color: $darkGray;
  text-decoration: underline;
}

.submitIDSButton {
  margin-bottom: 20px;
}

.fullWidth {
  width: 100%;
}

.mb1 {
  margin-bottom: 1rem;
}

.marginButtonInquireOffer {
  margin-top: 1.25rem;
}

.colorButtonInquireOffer {
  border-color: $lightGray;
  &:active {
    color: $darkGray;
  }
}

.marginButtonInquireOffer :global .MuiButton-startIcon {
  position: relative;
  top: 20%;
}

.voltimum {
  display: flex;
  justify-content: center;
  align-items: center;
}

.voltimumIcon {
  height: 1rem;
  margin: 2px 6px 0 5px;
}

.orderButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
