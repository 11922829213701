@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.skeletonSearchBar {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
}

.skeletonFilters {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
}
