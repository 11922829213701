@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.overlayGroup {
  display: flex;
  row-gap: 1rem;
  column-gap: 1.5rem;
  flex-wrap: wrap;

  @include below-lg {
    column-gap: 1rem;
  }
}

.collapseHeader {
  justify-content: unset;
  gap: 0.5rem;
}

.collapseSvgWrapper {
  @include below-lg {
    height: 20px;
    width: 20px;
    > svg {
      height: 20px;
      width: 20px;
    }
  }
}

.wrapper:not(:last-child) {
  margin: 0 1rem 1.5rem 1rem;
  @include sm {
    margin: 0 1.5rem 1.5rem 1.5rem;
  }
  @include lg {
    margin: 0 0 1.5rem 0;
  }
}
.wrapper:last-child {
  margin: 0 1rem;
  @include sm {
    margin: 0 1.5rem;
  }
  @include lg {
    margin: 0;
  }
}
