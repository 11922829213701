@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'assets/theme/mixins.scss';

.toolbar {
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: space-between;
  @include lg {
    height: 1.75rem;
  }
}
