@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.contentWrapper {
  position: relative;

  .pageWrapper {
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    overflow-y: auto;
  }
}

.wrapper {
  width: 100%;
  background-color: $lightGray5K;
}

.root {
  max-width: $shopMaxWidthExtended;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
}
