@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.gallery {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  &.tablet {
    height: 35rem;
    flex-direction: row;
  }
}

.galleryPlacement {
  &Bottom {
    grid-template-rows: 1fr auto;
  }

  &Right {
    grid-template-columns: 1fr auto;
  }
}

.selectedImageWrap {
  width: 100%;
  position: relative;
}
.mobile {
  .selectedImageWrap {
    height: 22rem;
  }
}
.tablet {
  .selectedImageWrap {
    flex: 1;
  }
}

.aspectRatioWrap {
  overflow-y: auto;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  width: 100%;
}

.wrap {
  width: 100%;
}

.contentHold {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.image {
  max-width: calc(100% - 1.5rem);
  max-height: calc(100% - 1.5rem);
}

.zoomInButton {
  position: absolute;
  bottom: 0;
  right: 0;
}

.thumbsWrapper {
  overflow: hidden;
  &.withNav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
}
