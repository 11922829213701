@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins';
@import 'libs/assets/theme/coreVariablesV2';

.root {
  .collapseHeader {
    justify-content: flex-start;
    padding: 0.25rem 0;
  }

  .headerText {
    justify-content: flex-start;
    padding: 0.625rem 0;
  }
}

.link {
  text-decoration: none;
  display: block;
}

.linkIcon,
.linkIcon * {
  color: $red100pct !important;
}

.addressInfoPopper .addressInfoTooltip {
  background-color: $white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  color: $darkGray;
  border-radius: 0.25rem;
  padding: 1rem;
}

.workingHours {
  display: grid;
  grid-template-columns: auto max-content;
  column-gap: 0.25rem;
  justify-content: start;
}

@include sm {
  .root {
    .collapseHeader {
      padding: 0.625rem 0;
    }
  }

  .linkButton {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: $red100pct;
    outline: none;
  }

  .addressesCollapse {
    margin-top: 0.5rem;
    padding-bottom: 1rem;
  }

  .addressGrid {
    display: grid;
    grid-template-columns: auto repeat(3, max-content);
    gap: 1rem 1.5rem;

    &.mainAddress {
      padding: 0.625rem 0;
    }

    .divider {
      height: 1px;
      border-top: 1px solid $lightGray;
      grid-column: 1/-1;
    }
  }

  .addressInfo {
    width: 20px;
    height: 20px;
    color: $darkGray;
  }
}

@include below-sm {
  .addressGrid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, auto) max-content;
    gap: 0.375rem 0.5rem;
  }

  .addressesCollapse {
    margin-top: 0.5rem;
  }

  .filialeName {
    grid-column: 1/-1;
    margin-bottom: 0.5rem;
  }
}
