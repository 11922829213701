@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.supplierCell {
  display: grid;
  grid-auto-flow: row;
  padding-bottom: 1rem;
  gap: 2.3125rem;

  > * {
    min-width: 0; /* Reset min-width to allow the columns to shrink */
  }

  &.twoColumnGrid {
    grid-template-columns: 1fr 1fr;
  }

  &.threeColumnGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.fourColumnGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include below-lg {
    gap: 1rem;
    padding-bottom: 1rem;
  }
}

.supplierlistitem {
  overflow: hidden;
  flex: 1;
  min-height: 6.25rem;

  .skeleton {
    width: 100%;
    height: 100%;
  }

  &.dummy {
    height: 14.5rem;
  }

  &:first-child {
    margin-right: 0.5rem;
  }

  &:last-child {
    margin-left: 0.5rem;
  }

  @include below-md {
    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
}
