@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/elevations.scss';

.card {
  padding: 1rem;
}

.content {
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
}

.header {
  width: 100%;
  justify-content: space-between;
}

.rotated {
  transform: rotate(360deg) !important;
}

.icon {
  transform: rotate(180deg);
}
