@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.long-list-wrapper {
  padding-bottom: 4rem;
  display: flex;
}

.long-list-items {
  display: flex;
  flex-direction: column;
  flex: 100;
  @include rowGap(1.5rem);
  overflow: hidden;
}

.input-wrapper {
  width: 100%;
}

.search-icon {
  margin-right: 0.25rem;
}

.input-root {
  width: 100%;
  > div {
    padding: 0.375rem 0.75rem;
  }
}
