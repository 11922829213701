@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.wrapper {
  background: $white;
  padding: 1rem;
  border-radius: 4px;

  .divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-color: $darkGray34K;
  }
}
