@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.controlWrapper {
  display: flex;
  flex-direction: column;
}

.searchWrapper {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  @include below-sm {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.mobileSortBtn {
  background-color: $white;
  border: 1px solid $darkGray;
  border-radius: 0.25rem;
  flex-shrink: 0;
  padding: 0.325rem;

  @include below-sm {
    gap: 0.5rem;
  }
}

.sortFiltersWrapper {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include above-sm {
    .filtersWrapper {
      max-width: calc(100% - 18rem);
    }
  }

  @include below-sm {
    flex-wrap: wrap;

    .filtersWrapper {
      order: 2;
      width: 100%;
    }

    .sortWrapper {
      order: 1;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

.select {
  width: 100%;
  @include sm {
    margin-left: 1rem;
    width: auto;
  }
  @include lg {
    margin-left: 2rem;
  }
}
