@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.pinnedNews-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cardsWrapper {
  width: 21.875rem;
  height: 24.25rem;
  padding: 5px;
  box-sizing: border-box;
  @include below-md {
    width: 100%;
    height: 26rem;
  }
}

.pinnedSectionWrapper {
  margin-top: 0;
  @include below-md {
    margin-bottom: 1.5rem;
  }
}

.cardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  height: 20.75rem;
  gap: 1rem;

  @include below-lg {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  @include below-md {
    height: 100%;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;

  @include below-md {
    flex-direction: unset;
    display: unset;
  }
}

.imageWrapper {
  height: 100%;

  @include below-md {
    height: 14.375rem;
  }

  @include below-sm {
    height: 18.75rem;
  }
}
