@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.defaultPadding th {
  padding: 0 0 0 1rem;
}

.centered {
  text-align: center;
}

.grayedOut {
  color: $darkGray55K;
}

.textSmall {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.pointer {
  cursor: pointer;
}
