@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.scanToolbarWrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}

.scanbox {
  border: 2px solid $white;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  display: block;
  left: 50%;
  height: 70vw;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
}

.closeicon,
.flashicon {
  position: absolute;
  top: 5rem;
  z-index: 1;
  color: $white;
}
.closeicon {
  left: 2.5rem;
}

.closeiconLandscape {
  left: 3.5rem;
  top: 1.5rem;
}

.flashicon {
  right: 2.5rem;
}

.flashiconLandscape {
  right: 3.5rem;
  top: 1.5rem;
}
