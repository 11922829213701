@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.mainWrapper {
  display: inline-block;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  background-color: $darkGray;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;

  :global .MuiTypography-root {
    color: $white;
  }
}

.boxTextAndCloseButton {
  display: flex;
}

.text {
  margin-right: 0.5rem;
}

.thumbs {
  display: flex;
  justify-content: center;
  > :first-child {
    margin-right: 2.5rem;
  }
}

.marginTopHalfRem {
  margin-top: 0.5rem;
}
