@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    .mejs__container,
    .mejs__overlay-play {
      max-height: 100%;
      max-width: 100%;
      video {
        max-width: 100%;
        max-height: 100%;
      }

      // We don't need to show the central play button in portrait mode, but there isn't a suitable control to remove it without using CSS
      .mejs__overlay-button {
        @media screen and (orientation: portrait) {
          @include below-sm {
            display: none;
          }
        }
      }
    }
  }
}
