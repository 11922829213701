@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.textLine2 {
  text-align: center;
}

.marginsForTextLine2 {
  margin-left: 7.625rem;
  margin-right: 7.625rem;
}

.headerText {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.marginBottomHalfRem {
  margin-bottom: 0.5rem;
}

.marginBottomOneAndHalfRem {
  margin-bottom: 1.5rem;
}

.marginRightHalfRem {
  margin-right: 0.5rem;
}

.marginRightOneAndHalfRem {
  margin-right: 1.5rem;
}

.dataBox {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.saveButton {
  width: 18.3125rem;

  @include below-md {
    width: 100%;
  }
}

.fullWidthButton {
  width: 100%;
}

.saveButtonsBox {
  margin-left: 4rem;
  margin-right: 4rem;
  display: flex;
  justify-content: space-between;

  @include below-md {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.submitButton {
  width: 37.625rem;
  left: 4rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

$loader-size: 8rem;
$check-height: calc(#{$loader-size}/ 2);
$check-width: calc(#{$check-height}/ 2);
$check-left: calc(#{$loader-size}/ 6 + #{$loader-size}/ 12);

.circleLoader {
  border: 0.625rem solid $green;
  position: relative;
}

.circleLoader,
.circleLoader:after {
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.checkMark {
  opacity: 1;
  height: $check-height;
  width: $check-width;
  transform-origin: left top;
  border-right: 0.625rem solid $green;
  border-top: 0.625rem solid $green;
  content: '';
  left: 1.4rem;
  top: 3.7rem;
  position: absolute;
  animation-duration: 1.2s;
  animation-timing-function: ease;
  animation-name: checkMark;
  transform: scaleX(-1) rotate(135deg);
}

@keyframes checkMark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
