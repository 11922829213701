@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins';
@import 'libs/assets/theme/coreVariablesV2';

.root {
  padding: 1rem;
}

.main {
  display: grid;
  grid-template-columns: calc(112px + 1rem) auto auto;
  grid-template-rows: repeat(2, min-content) 1fr repeat(2, min-content);
  grid-template-areas:
    'img    link     link'
    'img    title    title'
    'img    cat      cat'
    'descr  descr    descr'
    '.      .        price';

  @include sm {
    grid-template-columns: calc(112px + 1rem) auto auto;
    grid-template-rows: repeat(2, min-content) 1fr min-content;
    grid-template-areas:
      'img   title   link'
      'img   cat     cat'
      'img   descr   descr'
      '.     .       price';
  }
}

.img {
  grid-area: img;
}

.title {
  grid-area: title;
}

.description {
  grid-area: descr;
  margin-top: 1.2rem;
  @include sm {
    margin-top: 1rem;
  }
}

.category {
  grid-area: cat;
  margin-top: 0.25rem;
}

.articleLinkWrapper {
  grid-area: link;

  @include below-sm {
    padding: 6px 0;

    & + .title {
      margin-top: 0.3rem;
    }
  }

  @include sm {
    justify-self: end;
  }
}

.price {
  grid-area: price;
  justify-self: end;
  margin-top: 1rem;
}

.articleLink {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  align-self: center;
  text-decoration: none;
  color: $darkGray;
  cursor: pointer;

  .icon {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
  }
}

.accessories {
  margin-top: 0.8rem;

  @include sm {
    margin-top: 1.5rem;
  }
}

.accessoriesGrid {
  display: grid;
  gap: 1rem 0.5rem;
  grid-template-columns: auto max-content;
}

.accessoryPrice {
  justify-self: end;
}

.divider {
  margin-top: 0.7rem;

  @include sm {
    margin-top: 1.5rem;
  }
}

.addresses {
  margin-top: 0.875rem;
  @include sm {
    margin-top: 1rem;
  }
}

.root .collapseHeader {
  justify-content: flex-start;
}
