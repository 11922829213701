@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.actionsRectangle {
  position: absolute;
  bottom: 0;
  left: 0;
  @include dropShadow2;
  background-color: $white;
  width: 100%;
}

.weightBody {
  font-weight: 400;
}

.contentWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
  padding: 0 1.25rem;
  @include sm {
    height: 3rem;
  }
  @include md {
    padding: 0 1.75rem;
  }
  @include lg {
    height: 2.25rem;
    padding: 0 1rem;
  }
}

.blockIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: $red100pct;
  margin-right: 0.5rem;
}

.centered {
  align-self: center;
}

.flexed {
  display: flex;
}

.evenMargin {
  margin-top: auto;
  margin-bottom: auto;
}

.text {
  display: flex;
  flex-direction: column;
  @include sm {
    flex-direction: row;
    gap: 0.25rem;
  }
}
