@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

$catGap: 1rem;
$catHeight: 2.5rem;

.card {
  @include rowGap(1rem);
}

.mobileCard {
  padding-left: 0;
  padding-right: 0;
}

.root {
  display: flex;
  flex-direction: column;
}

.categories {
  @include rowGap($catGap);
  overflow: hidden;
}

.category {
  display: flex;
  cursor: pointer;
  height: $catHeight;
  align-items: center;
}

.categoryIcon {
  margin-right: 0.5rem;
}

.stackScreen {
  position: static;
  width: auto;
  height: auto;
  overflow: unset;
}

.collapseComp {
  padding: 0;
}

:export {
  catGap: $catGap;
  catHeight: $catHeight;
}
