@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.dialog {
  :global .MuiDialogContent-root {
    padding: 0;
  }

  &.mobileDialog {
    :global .MuiPaper-root {
      max-width: unset;
    }
  }
}

.paper {
  background-color: $lightGray5K;
  padding: 2rem 2rem 1.5rem 2rem;
  max-width: 45.625rem;

  &.paperMobile {
    padding: 1.5rem 1rem;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  :global .MuiTypography-root {
    font-size: 1.25rem;
  }
}

.close {
  justify-content: end;
  padding-right: 0;

  span {
    margin-right: 0;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.content {
  overflow-x: hidden;
}

.progressBar {
  width: 45rem;
  margin-left: -3rem;
  padding-top: 1rem;

  &.dynamicWidth {
    width: 100%;
    margin-left: unset;
  }
}

.actions {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.firstStep {
    justify-content: flex-end;
  }
}
