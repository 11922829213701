@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.reducedPaddingBottom {
  :global .MuiCardContent-root:last-child {
    padding-bottom: 1rem;
  }
}

.marginItem {
  height: 1.5rem;
  max-width: 100%;

  &:global(.MuiGrid-item) {
    max-width: 100%;
  }

  span {
    display: inline-block;
    max-width: inherit;
  }
}

.name {
  margin-top: 0.5rem;

  &:global(.MuiGrid-item) {
    max-width: 100%;
  }
}

.netSum {
  height: 1.25rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.imageGridForProducts,
.imageGridForSkeletons {
  margin-bottom: 1rem;
  margin-top: 1rem;
  @include lg {
    margin-top: 1.5rem;
    &.minimisedTopMargin {
      margin-top: 1rem;
    }
  }
}
.imageGridForProducts {
  padding-right: 0.917rem;
}

.imageList {
  margin-bottom: 0;
}

.fitWidth {
  width: fit-content;
}

.previewImage {
  width: 1.5rem !important;
  max-height: 1.5rem !important;
  object-fit: contain !important;
}

.moreIcon {
  color: $darkGray21K;
  height: 1.125rem;
  width: 1.125rem;
}

.skeletonWrapper {
  padding: 1rem;
  height: 10.875rem;
}
.skeletonHeader,
.skeletonItemShort,
.skeletonItemTall {
  display: flex;
  align-items: center;
}
.skeletonHeader {
  height: 2.063rem;
}
.skeletonItemShort {
  height: 1.875rem;
}
.skeletonItemTall {
  height: 2rem;
}

.textFieldInput {
  width: 100%;
  :global .MuiInputBase-root {
    min-width: 100%;
    font-weight: 700;
    font-size: 1rem;
    background-color: $white;
    border-color: $lightGray;
    height: 1.5rem;
    :global .Mui-focused fieldset {
      border: 0.0625rem solid $darkGray;
    }
  }

  :global .MuiOutlinedInput-input {
    padding-left: 0.75rem;
    text-align: start;
  }
}

.textFieldInputError {
  :global .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0.5rem;
  }
  :global .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 0.0625rem solid $primaryRed;
  }
}
