@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.mobileRoot {
  display: flex;
  @include columnGap(0.5rem);
}

.tabletPortraitRoot {
  display: flex;
  @include columnGap(1.5rem);
}
