@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.mobileRadioGroupPopover {
  width: 100%;
  margin-bottom: calc(3.5rem + var(--obeta-safe-area-bottom-root));
}

.paper {
  background-color: $lightGray5K;
}
