@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  display: flex;
  overflow: hidden;
  gap: 1.5rem;
  justify-content: space-between;
  flex-wrap: wrap;

  @include below-sm {
    margin-bottom: 1rem;
    gap: 1rem 1.5rem;
  }
  @include above-md {
    flex-wrap: nowrap;
  }
  @include above-sm {
    margin-bottom: 1.5rem;
  }
}

.meta {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  color: $darkGray;
  width: 100%;
  width: fit-content;
}

.properties {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .property {
    width: auto;
    padding-right: 1rem;
  }
}

.companyLogo {
  max-height: 4rem; //todo - the design system says its ought to be 64px, but many logos then need to be stretched and just look odd, therefore s.krau decided on the 4.4.23 to give it a max height of 4rem and if smaller use the intrinsic size
  @include above-md {
    align-self: flex-end;
  }
}
