@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.controlWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 1.75rem;
}

.filterBtn {
  background-color: $white;
  border: 1px solid $darkGray;
  border-radius: 0.25rem;
  flex-shrink: 0;
  padding: 0.35rem;

  @include below-sm {
    gap: 0.5rem;
    padding: 0.35rem 0.75rem;
  }
}

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include below-sm {
    justify-content: flex-end;
  }
}

.select {
  width: 100%;
  @include sm {
    margin-left: 1rem;
    width: auto;
  }
  @include lg {
    margin-left: 2rem;
  }
}
