@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: 45.625rem;
  margin-left: auto;
  margin-right: auto;
}
.headline {
  margin-bottom: 0.5rem;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.helperTextIcon {
  margin-right: 0.5rem;
}

.helperTextContainer {
  margin: 0.25rem 0 0 0;
}
.requiredText {
  align-self: flex-end;
}
.submitButton {
  max-width: fit-content;
}
.submitButtonContainer {
  align-self: flex-end;
}
