@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.additionalText {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  &Input {
    height: 5.75rem;
    :global .MuiOutlinedInput-root {
      height: 5.75rem;
    }
  }
}

.orderDeclarations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.commissionGrid {
  @include md {
    padding-right: 1rem;
  }
}

.text_input {
  margin-top: 0.25rem;
  width: 100%;
}

.helperTextIcon {
  margin-right: 0.25rem;
}

.infoMaxLengthGrid {
  text-align: right;
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 0; // prevents indentation of validation error shown in helper text
}
