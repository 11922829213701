@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.checkoutEditAddressOverlay {
  position: fixed;
  /* The positioning is submit to possible change */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 0.25rem;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15), 0 -5px 5px rgba(0, 0, 0, 0.15);
  border: none;
  color: $darkGray;
  max-height: 100%;
  overflow: scroll;
  @include below-sm {
    $paddingTop: 1rem;
    $paddingBottom: 1rem;
    $paddingLeft: 1rem;
    $paddingRight: 1rem;
    height: calc(100% - $paddingTop - $paddingBottom);
    width: calc(100% - $paddingLeft - $paddingRight);
  }
}

.buttonWrapper {
  @include below-sm {
    height: 100%;
  }
}

.typographyWrapper {
  margin-top: 1rem;
}

.singleLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > .typographyWrapper {
    width: 50%;
  }
  > .typographyWrapper:first-of-type {
    margin-right: 1rem;
  }
}

.buttonAddressLocationCard {
  padding: 0;
  vertical-align: bottom;
  margin-right: 1rem;
}

.text_input {
  width: 100%;
  margin-top: 0.25rem !important;
}
.divider {
  margin-top: 1rem;
  @include below-sm {
    margin-bottom: 0.5rem;
  }
  @include sm {
    margin-bottom: 1rem;
  }
  color: $lightGray;
  border-color: $lightGray;
}
.addAsNewDeliveryAddressButton {
  margin-right: 1.5rem;
  padding-right: 0;
}
