@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.moreMenu {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.takePicturePopover {
  padding: 0;

  @media (orientation: landscape) {
    padding-left: calc(1rem + var(--obeta-safe-area-top-root));
    padding-bottom: var(--obeta-safe-area-bottom-root);
  }

  @media (orientation: portrait) {
    padding-bottom: var(--obeta-safe-area-bottom-root);
  }

  .title {
    opacity: 0.5;
  }
}
