@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/mixins.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 4rem 4rem;
  gap: 2rem;
  width: 45.625rem;
  background-color: $white;
  border-radius: 0.5rem;
  @include dropShadow1;
  border: none;
  color: $darkGray;

  @include below-md {
    width: 100%;
  }
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @include below-sm {
    $paddingTop: 1rem;
    $paddingBottom: 1rem;
    $paddingLeft: 1rem;
    $paddingRight: 1rem;
    height: calc(100% - $paddingTop - $paddingBottom);
    width: calc(100% - $paddingLeft - $paddingRight);
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.headlines,
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  align-self: stretch;
  flex-grow: 0;
  text-align: center;
}
