@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

$itemHeight: 2.5rem;
$itemGap: 0.25rem;
$maxVisibleItems: 5;

.menuItem {
  height: $itemHeight;
}

.menuItemTitle {
  color: $darkGray;
  line-height: 1.5rem;
  height: 1.5rem;
}

.menuItemId {
  color: $darkGray55K;
}

.actionButton {
  height: 2.5rem;
}

.scrollbar {
  max-height: $itemHeight * $maxVisibleItems + ($itemGap * $maxVisibleItems - $itemGap); // make sure scroll appears if more than $maxVisibleItems items are rendered
  @include rowGap($itemGap);
}

.paper {
  grid-gap: 0.25rem;
}
.btn-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 2.5rem;
  cursor: pointer;
  span {
    margin-left: 0.25rem;
  }
}
