@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import '../../../../../assets/theme/mixins';
@import '../../../../../assets/theme/coreVariablesV2';

$mobileSidebarPadding: 1.5rem;
$mobileActionsHeight: 64px;

.sidebarRoot {
  color: $darkGray;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include md {
    width: 16rem;
  }
}

.mobileRoot {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: $white;
  overflow: auto;
  padding: $mobileSidebarPadding;
  padding-bottom: calc(
    $mobileActionsHeight + $mobileSidebarPadding + var(--obeta-safe-area-bottom-root)
  );
}

.sidebarActions {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $white;
  padding: 1rem 1.5rem;
  padding-bottom: calc(1rem + var(--obeta-safe-area-bottom-root));

  & .action {
    @include below-sm {
      flex: 1 1 auto;

      &:first-child {
        flex: 0 0 auto;
      }
    }

    @include sm {
      flex: 1 1 50%;
    }
  }
}
