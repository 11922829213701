@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.bottom {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bottomButtons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.flagAndSort {
  display: flex;
  flex-shrink: 0;
  gap: 2rem;
  margin-top: 0;
  width: auto;
}

.filterToggleButton {
  background-color: $white !important;
  border: 1px solid $darkGray;
  border-radius: 0.25rem;
  flex-shrink: 0;
  padding: 0.35rem;
}

.search {
  flex-grow: 1;
  margin-bottom: 0;
}

.top {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 100%;
  @include sm {
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
