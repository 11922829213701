@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.currentLocationMarker {
  border: 5px solid #e2061b;
  height: 28px;
  border-radius: 50%;
  width: 27px;
  transform: translate(-50%, -50%);
  background: #ffffff;
}
