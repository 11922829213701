@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.infoItem {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  &Column {
    align-items: start;
    flex-direction: column;
  }
  &Icon {
    margin-left: 0.5rem;
    width: 1.25rem;
  }
}
