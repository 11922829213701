@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import '../../../../../libs/assets/theme/mixins.scss';

.button {
  margin-top: 1rem;
  width: fit-content !important;
}

.bodyContentWrapper {
  margin-top: 1rem;
}

.icon {
  transform: rotate(360deg);
}

.rotated {
  transform: rotate(180deg);
}

.svgContainer {
  height: 1.25rem;
  width: 1.25rem;
  > svg {
    height: 1.25rem;
    width: 1.25rem;
  }
  @include below-lg {
    height: 2rem;
    width: 2rem;
    > svg {
      margin: 0.25rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

.wrapperCheckboxInCollapse {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
