@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import '../../../../../libs/assets/theme/mixins.scss';

@mixin oxomiIcon {
  width: 1.25rem;
  height: 1.25rem;
  background-size: 1.25rem 1.25rem;
}

@mixin baseTextOverrideOxomi {
  font-style: normal;
  font-size: 0.875rem;
  color: $darkGray;
}

.outerGrid {
  flex-direction: column;
  padding: 2rem;
  font-family: 'Lato', serif;
  :global {
    .oxomi-catalog {
      margin-right: 0;
    }
    .oxomi-content-container {
      display: flex;
      flex-flow: wrap;
      @include below-sm {
        justify-content: space-between;
      }
    }
    .oxomi-floater,
    .oxomi-document-preview-cell {
      margin: 0;
      padding: 0;
      @include below-sm {
        width: 9.375rem; //150px;
      }
      width: 11.75rem; //188px;
      align-items: flex-start;
    }
    .oxomi-border {
      padding: 0;
    }

    .oxomi-catalog-title {
      @include baseTextOverrideOxomi();
      margin-top: 0.5rem;
      font-weight: 400;
      line-height: 1.25rem;
    }

    .oxomi-catalog-container {
      margin-bottom: 2rem;
    }

    .oxomi-catalog-links {
      padding: 0;
    }

    a.oxomi-link {
      @include baseTextOverrideOxomi();
      font-weight: 700;
      line-height: 1.25rem;
      margin-left: 0.5rem;
    }

    $svg-data-uri-prefix: 'data:image/svg+xml;utf8,';

    .oxomi-view-icon {
      $oxomiPreviewIcon: "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M19 19V5H5V19H19ZM5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM7 17H14V15H7V17ZM7 13H17V11H7V13ZM7 9H17V7H7V9Z' fill='currentColor'/></svg>";

      @include oxomiIcon();
      margin-left: 0;
      background-image: url($svg-data-uri-prefix + $oxomiPreviewIcon);
      color: $darkGray;
      cursor: pointer;
    }

    .oxomi-download-icon {
      $oxomiDownloadIcon: "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z' fill='currentColor'/></svg>";

      @include oxomiIcon();
      margin-left: 0;
      background-image: url($svg-data-uri-prefix + $oxomiDownloadIcon);
      color: $darkGray;
      cursor: pointer;
    }

    .oxomi-width-slave {
      :nth-child(2) {
        margin-bottom: 0.5rem;
      }
    }
    .oxomi-pages-grid {
      display: flex;
      flex-flow: wrap;
      gap: 2rem;
    }
  }
}

.outerGridMobileSpecific {
  padding: 1.5rem 1rem;
}

.marginBottomTwoRem {
  margin-bottom: 2rem;
}

.marginBottomOneRem {
  margin-bottom: 1rem;
}

.oxomiCatalogTarget {
  display: flex;
  flex-flow: wrap;
  gap: 1rem;
}

.mobileWidthLimit {
  width: 21.75rem;
}

.defaultWidthLimit {
  width: 43.5rem;
}

.noPadding {
  padding: 0;
}

.marginTopOneRem {
  margin-top: 1rem;
}
