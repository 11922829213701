@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.openPositionSearch {
  display: flex;

  :global .MuiTextField-root {
    height: 100%;
  }
}

.inputContainer {
  height: 100%;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;

  input {
    height: 2.5rem;
  }
}

.selectContainer {
  display: flex;
  gap: 1rem;
  margin-right: 0.75rem;
  @include below-sm {
    gap: 0.5rem;
  }
}

.searchIconButton {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $red100pct;
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
