@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

// Note: Must be applied after .title when both classes are used, to ensure that 'align-items: center' takes effect
.titleContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: inherit;
}

.buttonAdapted {
  border: none;
  background: inherit;
}

.title {
  align-items: center;
  padding-bottom: 0.85rem;
  border-bottom: 2px solid transparent;
  @include sm {
    padding-bottom: 0;
  }
  // @include md {
  //   padding-bottom: 0.85rem;
  // }
  @include lg {
    padding-bottom: 0;
    border-bottom: unset;
  }
}

.headerWrapper {
  padding: 0.5rem 0 1rem 0;
  margin: 0 1rem;
}

.separator {
  border-bottom: 1px solid $lightGray;
}

.footerWrapper {
  padding: 0.5rem 0;
  margin: 0 1rem;
  border-top: 1px solid $lightGray;
}

.whiteText {
  color: $white;
  display: flex;
  align-items: center;
}

.blackText {
  color: $darkGray;
  & > svg {
    > path,
    > circle {
      fill: $darkGray;
    }
  }
}

.category-menu-item {
  @include ellipsis;
}

.sub-menu {
  display: flex;
  justify-content: space-between;
}

.category-menu-sub-item {
  @include ellipsis;
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.arrow-icon {
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
}

.arrow-icon-big {
  svg {
    width: 1rem;
    height: 1rem;
  }
}

.list-item {
  gap: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 36px;

  &:first-of-type {
    margin-top: 8px;
  }

  &:last-of-type {
    margin-bottom: 8px;
  }
}

.list-item-icon {
  min-width: unset !important;
}
