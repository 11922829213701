@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  border-radius: 0.25rem;
  overflow: auto;
}

.fitContent {
  width: fit-content;
  height: fit-content;
}

.thresholdXs {
  max-width: calc(100% - #{2 * $modalMarginThreshold});
  max-height: calc(100% - #{2 * $modalMarginThreshold});
}

.thresholdLg {
  max-width: calc(100% - 2 * 10.3rem);
  max-height: calc(100% - 2 * 5.3rem);
}

.fullscreen {
  width: 100%;
  height: 100%;
  max-width: unset;
  max-height: unset;
  border-radius: 0;
}

.close {
  color: #3f3f3e;
  position: absolute;
  top: calc(2rem + var(--obeta-safe-area-top-root));
  right: 2rem;
  z-index: 1000;
}
