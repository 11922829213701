@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  @include rowGap(1rem);

  @include md {
    @include rowGap(1.5rem);
  }
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include columnGap(1.5rem);
}

.titleRoot {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.icon {
  margin-right: 1rem;
}

.footer {
  width: 100%;
  height: 0.25rem;
}
