@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.wrapper {
  padding-top: 1.5rem;
}

.createNew {
  height: 100%;
  align-self: center;
  :global .MuiButton-startIcon {
    margin: 0;
    @include sm {
      margin-right: 0.5rem;
    }
  }
  padding: 1rem 0 0;
}
