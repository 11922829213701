@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.icon {
  flex-shrink: 0;
  width: 1rem;
}

.root {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
}

.paper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75rem;
  height: 2rem;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  gap: 0.5rem;
}

.popoverPaper {
  padding: 1rem;
}

.borderless {
  border: unset;
}
