@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.filterButton {
  border-radius: 0;
  display: flex;
  gap: 0.5rem;
  @include lg {
    gap: 0.25rem;
  }
  height: 2rem;
  min-width: 0;
  padding: 0;
  transition-duration: 0s;
  &:hover {
    background-color: transparent;
  }
}

.icon {
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
}

.active {
  border-bottom: 2px solid $primaryRed;
  * {
    color: $primaryRed;
  }
  &:active {
    * {
      color: $red89pct;
    }
  }
}

.inactive {
  border-bottom: 2px solid transparent;
  * {
    color: $darkGray;
  }
  &:active {
    * {
      color: $darkGray55K;
    }
  }
}
