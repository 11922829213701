@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.mapComponentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include dropShadow1;
}

.mapContainer {
  width: 100%;
  height: 37.5rem;
  @include below-sm {
    height: 25rem;
  }
}

.expandMap {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 20px;
  background: red;
  cursor: pointer;
}

.userLocationSetting {
  right: 24px;
  bottom: 96px;
}

.zoomInSetting {
  right: 24px;
  bottom: 60px;
}

.zoomOutSetting {
  right: 24px;
  bottom: 24px;
}

.fullscreenSetting {
  right: 24px;
  top: 24px;
}
