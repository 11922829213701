@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.container {
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.withOverlay {
    z-index: 1001;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 1000; /* Adjust the z-index based on your needs */
  display: flex;
  justify-content: center;
  align-items: center;
}
