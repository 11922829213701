@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.BarState {
  height: 2.75rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding: 0;
  margin-bottom: 1rem;
  width: 100%;
}
.Line {
  height: 0.25rem;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: currentColor;
}

@media (max-width: 768px) {
  .Line {
    width: 100%;
  }
}

.title {
  flex-grow: 0;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
}

.titleMobile {
  flex-grow: 0;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: transparent;
  padding: 0;
}
