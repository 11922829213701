@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.carousel {
  justify-content: center;
  display: flex;
  position: relative;
}

.wrapper {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  padding-top: $shopLastElementPadding;
  z-index: 10;
  position: relative;
  .cardWrapper {
    position: relative;
    width: 16rem;
    padding: 5px;
  }
}
