@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.status {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0.25rem;
  width: fit-content;
  margin: 0 auto;
  color: $darkGray;
}

.price {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  width: fit-content;
  place-items: center;
}

.priceDescription {
  color: $darkGray55K;
}

.root {
  padding: 1.5rem;
  background-color: $lightGray5K;
  border-radius: 0.25rem;
  display: grid;
  grid-area: Price;
  grid-auto-flow: row;
  grid-gap: 1.5rem;
  height: fit-content;
  position: relative;
  z-index: 11;
  @include below-sm {
    padding: 1.5rem 0;
  }
}

.header {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.25rem;
  width: fit-content;
  margin: 0 auto;
}

.statusGroup {
  margin: 0 auto;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.75rem;
}

.priceBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.oldPrice {
  font-size: 1rem;
  line-height: 1.5rem;
  color: $darkGray;
  font-weight: 400;
  letter-spacing: 0.025rem;
}

.priceDetails {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
}

.labels {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  margin: 0 auto;
}

.deliveryActions {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  place-items: center;
}

.deliveryActionButton {
  color: $darkGray55K;
  margin-left: 0.25rem;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -1rem;
}

.voltimumIcon {
  width: 6.5rem;
}

.submitGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
