@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.cardContainer {
  justify-content: center;
  display: flex;
  position: relative;
}

.cardWrapper {
  width: 21.875rem;
  height: 24.25rem;
  padding: 0 5px;
  box-sizing: border-box;

  @include below-sm {
    width: 100%;
    height: 26rem;
    padding: 0;
  }
}

.sectionWrapper {
  margin-top: 0;
}
