@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'assets/theme/mixins.scss';

$maxModalWidth: 68.75rem;

.wrapper {
  background-color: $white;
  overflow: auto;
  padding: 2.5rem;
  width: 100%;
  position: relative;

  @include below-sm {
    padding: calc(1.5rem + var(--obeta-safe-area-top-root)) 1rem 1.5rem 1rem;
  }

  .closeButton {
    padding: 0;

    .icon {
      width: 2rem;
      height: 2rem;
      color: rgba(63, 63, 62, 1);
    }
  }

  .titleWrapper {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filterSection {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin-bottom: 2rem;
    flex-direction: row;

    @include below-sm {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }

    .searchRoot {
      color: inherit;
      border-radius: 4px;
      border: solid 1px $darkGray55K;
      font-size: 14px;
    }

    .searchInput {
      width: 6.25rem;
      height: 100%;
      padding: 6px 12px;
    }
  }

  .processingIndication {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid $lightGray;

    .backButton {
      border: none;
    }
  }
}

.row {
  display: flex;
}

.templatesWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  padding-bottom: 2rem;

  @include below-lg {
    grid-template-columns: 1fr 1fr;
  }

  @include below-sm {
    grid-template-columns: 1fr;
  }
}

.infoRadioWithTitle {
  display: flex;
  margin-bottom: 0.75rem;
  gap: 0.5rem;
  min-width: 0;
  align-items: center;
}

.templateInfoSection {
  display: flex;
  flex-direction: column;
}

.templateSection {
  padding: 1rem;
  border: solid 1px transparent;
  border-radius: 4px;

  @include below-lg {
    padding: 1rem 5rem 1rem 2.5rem;
  }

  @include below-sm {
    padding: 1rem 3.75rem 1rem 1.75rem;
  }

  &.active {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px $red100pct;
  }
}

.cellSolid {
  border: 1px solid black;

  &.firstLeftCell {
    border-left: 1px solid black;
  }

  &.lastBottomCell {
    border-bottom: 1px solid black;
  }
}

.cellDashed {
  border: 1px dashed black;

  &.firstLeftCell {
    border-left: 1px dashed black;
  }

  &.lastBottomCell {
    border-bottom: 1px dashed black;
  }
}

.cell {
  background: white;
  border-bottom: none;
  border-left: none;
  box-sizing: border-box;
  &.engagedCell {
    background: $darkGray21K;
  }
}

.templateBlock {
  height: 100%;
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
}

.warning {
  font-size: 16px;
}
