@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import '../../../../../libs/assets/theme/mixins.scss';

/**
  Try to avoid using of nested css grids. It slows down performance in FF
  https://bugzilla.mozilla.org/show_bug.cgi?id=1612355
*/

.column {
  color: $darkGray;
}

.colStoreAddress {
  width: 14rem;
  padding-right: 2.5rem;
  @include below-sm {
    padding-right: 0;
    width: 100%;
  }
}

.colHeader {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.phoneWrapper {
  margin-top: 0.5rem;
  @include sm {
    margin-top: 0;
  }
}

.xsGap {
  margin-right: 0.25rem;
}

.mdGap {
  margin-right: 0.5rem;
}

.distance {
  color: $darkGray55K;
}
