@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.dialog {
  :global .MuiPaper-root {
    width: 17.688rem;
    margin: 0;
    padding-bottom: 0;
  }
}

// Workaround for bug: https://github.com/mui/material-ui/issues/23032
// Seems not always to occur on mobile devices
// Try to avoid fixed height and position in future if bug will be fixed
.dialogFixed {
  :global .MuiPaper-root {
    height: 11rem;
    position: fixed;
    bottom: 50%;
    top: 25%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.title {
  padding: 1rem;
  text-align: 'center';
  display: flex;
  justify-content: space-between;
}
.content {
  padding: 0 1rem 0 1rem;
  text-align: 'center';
}

.close {
  justify-content: end;
  padding-right: 0;

  span {
    margin-right: 0;
  }
}

.actions {
  padding: 1rem;
  text-align: 'center';
}

.textFieldInputError {
  :global .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0.5rem;
  }
  :global .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 0.0625rem solid $primaryRed;
  }
}
