@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.layout {
  flex-grow: 1;
  margin-right: 2.5rem;
}

.dateRangePicker {
  :global .MuiDateRangePickerDay-dayInsideRangeInterval {
    font-weight: 700;
  }
  :global .MuiDateRangePickerDay-dayOutsideRangeInterval {
    font-weight: 700;
  }
  :global .MuiPickersSlideTransition-root {
    height: 16rem !important;
    min-height: 0 !important;
  }
  :global .MuiDayCalendar-root {
    height: 16rem;
  }
  :global .MuiListItem-root {
    width: auto;
    margin-right: -1.5rem;
  }
  :global .MuiChip-root {
    height: 1.5rem;
    border-radius: 0.25rem;
    font-weight: 700;
    z-index: 10000;
  }
}

.calendarIcon {
  margin-top: 0.125rem;
  margin-right: -0.25rem;
}
