@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations';

.teaser-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  &:hover {
    @include dropShadow2;
  }

  position: relative;
}

.teaser-img-wrapper {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.teaser-title {
  color: $white;
  position: relative;
  width: 100%;
  @include multilineEllipsis;
  -webkit-line-clamp: 2;
}

.teaser-content {
  padding: 0 1rem 1.5rem;
  z-index: 2;

  &.teaser-content__small {
    padding: 0 1rem 1rem;
  }

  @include below-sm {
    &,
    &.teaser-content__small {
      padding: 0 1rem 1rem;
    }
  }
}
