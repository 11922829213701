@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.groupContainer {
  &.mobile {
    min-height: calc(100% - 12rem);
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 26rem;

  &.card {
    background-color: $white;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding: 1rem;
  }
  &.mobile {
    display: flex;
    flex: 1;
  }

  :global .MuiFormHelperText-root {
    margin-left: 0;
  }
}
