@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-wrapper {
  flex-direction: column;
  display: flex;
}

.content-item-wrapper {
  cursor: pointer;
  padding: 0.5rem 0;
}

.subItems {
  padding-left: 1rem;
}
