@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.descriptionOrderFailed {
  width: 24.1875rem;
  margin-left: 10.71875rem;
}

.mainContainerHeight {
  height: 28.75rem;
}

$loader-size: 8rem;
$error-left: $loader-size/4;

.circleLoader {
  border: 0.625rem solid $red100pct;
  position: relative;
}

.circleLoader,
.circleLoader:after {
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.failureIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.failureIcon {
  width: 11rem;
  height: 11rem;
  margin-top: 2.5rem;
  color: $red100pct;
}
