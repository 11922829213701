@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  font-weight: 700;
  color: $darkGray;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 0.25rem;
}
