@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.filters {
  color: $darkGray;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &Modal {
    width: 100%;
  }
  &Sidebar {
    width: 16rem;
  }
}

.mobile {
  position: relative;
  width: 100%;
  background-color: $lightGray5K;
  overflow: auto;
  padding: calc(1.5rem + var(--obeta-safe-area-top-root)) 1.5rem
    calc(5rem + var(--obeta-safe-area-bottom-root)) 1.5rem;
  @include below-sm {
    padding: calc(1.5rem + var(--obeta-safe-area-top-root)) 1rem
      calc(5rem + var(--obeta-safe-area-bottom-root)) 1rem;
  }
}
