@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import '../../../../../assets/theme/mixins';

.center {
  flex-grow: 0;
  display: flex;
  gap: 0.3125rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 45.625rem;
}
.centerMobile {
  flex-grow: 0;
  display: flex;
  gap: 0.3125rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.formContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  width: 45.625rem;
  max-width: 45.625rem;
  margin: 0 0 0 0;
}

.formContainerTabletWide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  width: 44.5rem;
  max-width: 44.5rem;
  margin: 0 0 0 0;
}

.formContainerMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.TextField {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.noPadding {
  padding: 0;
}
.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 2rem;
  gap: 1.5rem;
}

.left {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 3rem;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.size {
  width: 3.875rem;
  height: 1rem;
  flex-grow: 0;
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: left;
  color: $darkGray55K;
  padding: 0.5rem 0 0.5rem 0;
}
.helperTextIcon {
  margin-right: 0.5rem;
}

.itemRow {
  grid-row: 1;
  max-width: 100%;
}
.helperTextContainer {
  margin: 0.25rem 0 0 0;
}
