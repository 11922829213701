@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.actionsRectangle {
  position: absolute;
  bottom: 0;
  left: 0;
  @include dropShadow2;
  background-color: $white;
  width: 100%;
}

.weightBody {
  font-weight: 400;
}

.deleteIcon {
  @include below-sm {
    margin-left: 0.5rem;
  }

  margin-left: 1rem;
  @include lg {
    max-height: 1.25rem; //20px;
  }
}

.containerDiv {
  display: flex;
  @include below-lg {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.blockIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: $red100pct;
  margin-right: 0.5rem;
}

.centered {
  align-self: center;
}

.flexed {
  display: flex;
}

.evenMargin {
  margin-top: auto;
  margin-bottom: auto;
}

.infoReplacementText {
  font-weight: 700;
}
