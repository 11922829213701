@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

@mixin vminAspectRatio {
  /**
    makes sure that that image dimensions (width and height) are
     restricted by viewport's smaller dimension.
      We want to make sure that AspectRatio component (size based on "width") doesn't make
    image too big in landscape mode.

    TODO: header cuts some of the height. that means that max height is less than without height.
    use header height variables to calculate max vmin?
  */

  .gallery {
    margin: 0 auto;
    width: 100%;
  }

  .selectedImageWrap {
    max-width: 100vmin;
    margin: 0 auto;
  }
}

.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: fit-content;
  grid-area: Gallery;
  @include md {
    grid-area: Gallery;
  }

  @include vminAspectRatio;
}

.actions {
  width: 100%;
  .btn-wrapper {
    width: auto;
    button div div {
      height: fit-content;
      display: flex;
    }
  }
}

.galleryPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dopIcon {
  width: 20px;
  height: 20px;
}
