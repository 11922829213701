@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

$height: 5.25rem;
$mobileHeight: 3.5rem;
$mobileFullHeight: 7rem;

.topHeaderSection {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: $white;
  @include signUpSectionPadding;
  @include lg {
    max-width: calc(#{$shopMaxWidth} + 3rem);
    margin-left: auto;
    margin-right: auto;
  }
}

.letter {
  color: $red100pct;
  font-weight: bold;
}

.topHeaderContent {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  @include below-lg {
    max-width: $shopMaxWidth;
  }
}

.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 1rem;
}

:export {
  mobileFullHeight: $mobileFullHeight;
}
