@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.tag {
  padding: 0.125rem 0.25rem;
  border-radius: 0.125rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.25rem;
  height: 1.25rem;
  width: fit-content;
  align-items: center;
}

.red100pct {
  background-color: $red100pct;
  color: #fff;
}
