@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

$itemHeight: 1.5rem;

.root {
  @include rowGap(1rem);
}

.list {
  @include listMaxHeight($itemHeight, $listsGap);
}

.size {
  margin-left: 0.25rem;
}

.suppliersSortIcon {
  width: 1.25rem;
  height: 1.25rem;

  &.reversed {
    transform: scaleX(-1);
  }
}
