@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.appBarWrapper {
  width: 100%;
}

.header {
  display: flex;
  align-items: flex-start;
  row-gap: 1rem;
  @include sm {
    row-gap: 1.5rem;
  }
  margin-bottom: 1.5rem;
}
.actions {
  display: flex;
  flex-direction: column;
  @include sm {
    flex-direction: row;
  }
}

.templateDetails {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.toolbar {
  padding: 0 !important;
  justify-content: flex-start;
}

.skeletonSearchBar {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
}

.skeletonFilters {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
}

.skeletonItem {
  margin-bottom: 0.5rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
